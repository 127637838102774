import React, {
  HtmlHTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import { ZoozaContext } from "../services/zooza.context";
import { ZoozaContextTypes } from "../types/types";
import { useTranslation } from "react-i18next";

export const Agreements = ({
  checkedAgreements,
}: {
  checkedAgreements: Agreement[] | undefined;
}) => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [agreements, setAgreements] = useState<Agreement[] | undefined>();
  const parser = new DOMParser();
  const mandatoryAgreements = userData?.agreements?.filter(
    (agreement) => agreement.mandatory === 1
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const agreement = userData?.agreements.find(
      (agreement) => agreement.id === Number(event.target.id)
    );
    if (event.target.checked) {
      setAgreements([
        ...(agreements || ([] as Agreement[])),
        agreement as Agreement,
      ]);
    } else {
      setAgreements((prevdata: Agreement[] | undefined) => {
        if (!prevdata) return [];
        return prevdata.filter((item) => item.id !== agreement?.id);
      });
    }
  };

  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const agreementId = Number(event.target.id);

    setAgreements((prevdata: Agreement[] | undefined | null | any) => {
      if (!prevdata) return [];

      return prevdata.map((agreement: Agreement) =>
        agreement.id === agreementId
          ? { ...agreement, gave_consent: event.target.value === "true" }
          : agreement
      );
    });
  };

  useEffect(() => {
    if (mandatoryAgreements) {
      setAgreements(
        mandatoryAgreements.filter(
          (agreement) => agreement.mandatory === 1 && agreement.type === "radio"
        )
      );
    }
  }, []);

  return (
    <div>
      {userData?.agreements?.map((agreement) => {
        const parsedDocument = parser.parseFromString(
          agreement.name,
          "text/html"
        );
        const labelContent = parsedDocument.body.textContent || "";
        const anchorElement = parsedDocument.querySelector("a")?.textContent;
        const label = labelContent.replace(anchorElement || "", "");
        if (agreement.type === "checkbox") {
          return (
            <div key={agreement.id}>
              <input
                required={Boolean(agreement.mandatory)}
                id={String(agreement.id)}
                type={agreement.type}
                onChange={onChange}
                checked={checkedAgreements?.some(
                  (checkedAgreement) => checkedAgreement.id === agreement.id
                )}
              />
              <label htmlFor={String(agreement.id)}>
                {label}{" "}
                <a
                  href={"https://agreements.zooza.sk/" + agreement.id}
                  target="_blank"
                >
                  {anchorElement}
                </a>
              </label>
            </div>
          );
        } else if (agreement.type === "radio") {
          return (
            <RadioButtons
              agreement={agreement}
              label={label}
              anchorElement={anchorElement}
              onChangeRadio={onChangeRadio}
            />
          );
        }
      })}
    </div>
  );
};

const RadioButtons = ({
  agreement,
  label,
  anchorElement,
  onChangeRadio,
}: {
  agreement: Agreement;
  label: string;
  anchorElement: any;
  onChangeRadio: (e: any) => void;
}) => {
  const [checked, setChecked] = useState<boolean | null>(null);
  useEffect(() => {
    if (agreement.mandatory === 1) {
      setChecked(false);
    }
  }, []);
  const { t } = useTranslation();
  return (
    <div key={agreement.id}>
      <input
        required={Boolean(agreement.mandatory)}
        id={String(agreement.id)}
        type="radio"
        value={"true"}
        onChange={(e) => {
          onChangeRadio(e);
          setChecked(true);
        }}
        checked={checked === null ? false : checked}
      />
      <label htmlFor={String(agreement.id)}>{t("global.yes")}</label>
      <input
        required={Boolean(agreement.mandatory)}
        id={String(agreement.id)}
        type="radio"
        value={"false"}
        onChange={(e) => {
          onChangeRadio(e);
          setChecked(false);
        }}
        checked={checked === null ? false : !checked}
      />
      <label htmlFor={String(agreement.id)}>{t("global.yes")}</label>

      <label htmlFor={String(agreement.id)}>
        {label}{" "}
        <a href={"https://agreements.zooza.sk/" + agreement.id} target="_blank">
          {anchorElement}
        </a>
      </label>
    </div>
  );
};
