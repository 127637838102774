import {EventDetail} from "../../types/schedule-type";
import React, {useState} from "react";
import {ZoozaApi} from "../../services/Axios";
import {handleError} from "../../services/common-functions";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow
} from "../../components/zooza-card-components";
import {t} from "i18next";

export const EventPageNotes = ({event}: { event: EventDetail | undefined }) => {
    const [notes, setNotes] = useState({
        summary_public: event?.summary_public,
        summary: event?.summary,
    });
    const [isEditing, setIsEditing] = useState({
        summary_public: false,
        summary: false,
    });

    const onNoteChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        const id = e.currentTarget.id;
        setNotes({...notes, [id]: e.currentTarget.value});
    };
    const saveNote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.id;

        setIsEditing({...isEditing, [id]: false});
        try {
            const {data} = await ZoozaApi.put(`events/${event?.id}`, {
                [id]: notes[id as keyof typeof notes],
            });
        } catch (error: any) {
            handleError(error.response.data);
        }
    };
    // useEffect(() => {
    //   setNotes({
    //     summary_public: event?.summary_public,
    //     summary: event?.summary,
    //   });
    // }, [event]);
    return (
        <ZoozaCard>
            <ZoozaCardHeader
                title={t("global.title.notes")}
                icon="icon-pencil-3"
            ></ZoozaCardHeader>
            <ZoozaCardRow>
                <ZoozaCardCell>
                    <span>{t("global.title.public_note")}</span>
                    {notes.summary_public && !isEditing.summary_public && (
                        <blockquote
                            onClick={() =>
                                setIsEditing({...isEditing, summary_public: true})
                            }
                        >
                            {notes.summary_public}
                        </blockquote>
                    )}
                    {isEditing.summary_public && (
                        <div>
              <textarea
                  onChange={onNoteChange}
                  name="summary_public"
                  id="summary_public"
                  value={notes.summary_public || ""}
              />
                            <button onClick={saveNote} id="summary_public" className="z2">
                                {t("global.button.save")}
                            </button>
                            <button
                                onClick={() =>
                                    setIsEditing({...isEditing, summary_public: false})
                                }
                                className="z2"
                            >
                                {t("global.button.cancel")}
                            </button>
                        </div>
                    )}
                </ZoozaCardCell>
            </ZoozaCardRow>

            <ZoozaCardRow>
                <ZoozaCardCell>
                    <span>{t("global.title.summary_note")}</span>
                    {notes.summary && !isEditing.summary && (
                        <blockquote
                            onClick={() => setIsEditing({...isEditing, summary: true})}
                        >
                            {notes.summary}
                        </blockquote>
                    )}
                    {isEditing.summary && (
                        <div>
              <textarea
                  onChange={onNoteChange}
                  name="summary"
                  id="summary"
                  value={notes.summary || ""}
              />
                            <button onClick={saveNote} id="summary" className="z2">
                                {t("global.button.save")}
                            </button>
                            <button
                                onClick={() => setIsEditing({...isEditing, summary: false})}
                                className="z2"
                            >
                                {t("global.button.cancel")}
                            </button>
                        </div>
                    )}
                </ZoozaCardCell>
            </ZoozaCardRow>

            <ZoozaCardActions>
                <a
                    onClick={() => setIsEditing({...isEditing, summary_public: true})}
                    role="button"
                    className="z2 primary"
                >
                    {t("event.button.add_public_note")}
                </a>
                <a
                    onClick={() => setIsEditing({...isEditing, summary: true})}
                    role="button"
                    className="z2 primary"
                >
                    {t("event.button.add_summary_note")}
                </a>
            </ZoozaCardActions>
        </ZoozaCard>
    );
};
