import React from "react";
import {
    ZoozaRegistrationList, ZoozaRegistrationListNote,
    ZoozaRegistrationListRow, ZoozaRegistrationListRowAttendance,
    ZoozaRegistrationListRowEnd,
    ZoozaRegistrationListRowID,
    ZoozaRegistrationListRowName, ZoozaRegistrationListRowPayment
} from "../../components/ZoozaRegistrationList";
import {Order} from "../../types/orders-types";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import moment from "moment";

export const LinkedOrders = ({orders}: { orders: Order[] }) => {
    const {t} = useTranslation()
    return (
        <div>

            <ZoozaRegistrationList style={{width: "100%"}}>
                {orders?.map((order) => (
                    <><ZoozaRegistrationListRow>
                        <ZoozaRegistrationListRowID order_id={order.id} label_product={t("orders.label.product")} label_type={t("orders.label.type")}/>
                        <ZoozaRegistrationListRowName link_order_id={`/orders/${order.id.toString()}`}
                                                      customer_name={order.__users__full_name?order.__users__full_name: order.user.first_name + order.user.last_name  || t("orders.label.noname")}
                                                      customer_email={order.__users__email}
                                                      customer_phone={order.__users__phone}
                                                      label_customer={t("orders.label.customer")}/>


                    </ZoozaRegistrationListRow>

                        <ZoozaRegistrationListRowEnd>
                            <ZoozaRegistrationListRowPayment  label={t("orders.label.payment")} paid={order.__calc__paid}/>
                            <ZoozaRegistrationListRowAttendance label={t("orders.label.product")} product_name={order.product.name} />
                            <ZoozaRegistrationListNote label={t("orders.label.date_purchased")} date={moment(order.created).locale(i18n.language).format("LLL")} />


                        </ZoozaRegistrationListRowEnd></>
                ))}

            </ZoozaRegistrationList>
        </div>
    );
}
