import React from "react";
import "../styles/modal.css";
import { ReactComponent as Loader } from "../assets/img/loader_primary.svg";
import { ErrorType } from "../types/types";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  onSubmit?: (e?: any) => void;
  submitButton?: string;
  cancelButton?: string;
  buttonDisabled?: boolean;
  loading?: boolean;
  errors?: ErrorType;
};

export const Modal = ({
  children,
  isOpen,
  onClose,
  title,
  onSubmit,
  submitButton,
  cancelButton,
  buttonDisabled,
  loading,
  errors,
}: ModalProps) => {
  return (
    <section
      className={"z2 app_modal"}
      style={{ display: isOpen ? "grid" : "none" }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div style={{ zIndex: 100000 }} className={` app_modal_container`}>
        <div className="app_modal_head">
          <h3>{title}</h3>
          <a role="button" className="rounded right" onClick={onClose}>
            <span className="icon-cross"></span>
          </a>
        </div>
        <div className="app_modal_body">
          {children}
          {errors && (
            <div className="app_modal_errors">
              <p>{JSON.stringify(errors.errors)}</p>
            </div>
          )}
        </div>
        <div className="app_modal_actions">
          <button
            onClick={onSubmit}
            className="z2 primary"
            type="button"
            disabled={buttonDisabled}
          >
            {loading ? (
              <Loader className="spinner-button"></Loader>
            ) : (
              submitButton
            )}
          </button>
          <button type="button" className="z2" onClick={onClose}>
            {cancelButton}
          </button>
        </div>
      </div>
    </section>
  );
};
