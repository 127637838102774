import React, { useContext, useEffect, useState } from "react";

import {
  CreateRegistrationContextTypes,
  Member,
  ZoozaContextTypes,
} from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import { useLocation, useSearchParams } from "react-router-dom";
import { Event, Schedule } from "../../types/registration-types";
import { EventsCard } from "../../components/event-component/events-card";
import "moment/min/locales";
import moment from "moment";
import { RegistrationCard } from "./registration-card";
import { CreateRegistrationContext } from "./create-registration-context";
import { ScheduleCard } from "./schedule-card";
import { FetchingCard } from "./fetching-card";
import { useTranslation } from "react-i18next";

export const CreateRegistrationPage = () => {
  const { places, courses, members, loading, userData } =
    useContext<ZoozaContextTypes>(ZoozaContext);
  const {
    setCourse,
    course,
    place,
    setPlace,
    setRoom,
    setMember,
    pid,
    events,
    setEvents,
    setSchedule,
  } = useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [progress, setProgress] = useState(1);
  const initPlace = places?.find(
    (place) => place.id === Number(searchParams.get("place")?.split("_")[0])
  );

  const location = useLocation();
  const isMember =
    userData?.user?.role === "member" ||
    userData?.user?.role === "main_member" ||
    userData?.user?.role === "external_member";

  useEffect(() => {
    setPlace?.(initPlace);
  }, [loading, searchParams.get("place"), searchParams.get("member"), places]);

  useEffect(() => {
    setPlace?.(
      places?.find((place) => place.pid.split("_")[0] === pid?.split("_")[0])
    );
  }, [pid]);

  useEffect(() => {
    if (place && searchParams.get("place")?.split("_")[1] !== "0") {
      setRoom?.(
        place.rooms?.find((room) =>
          pid ? room.pid === pid : room.pid === searchParams.get("place")
        )
      );
    }
  }, [place, pid]);
  useEffect(() => {
    setCourse?.(
      courses?.find(
        (course) => course.id === Number(searchParams.get("course"))
      )
    );
  }, [searchParams.get("course"), loading]);

  useEffect(() => {
    if(location?.state?.skipSchedule){
      setProgress(3);
      setSchedule?.(location?.state?.schedule)
      setCourse?.(location?.state?.course)
    }
  }, [location])

  useEffect(() => {
    if (isMember) {
      setMember?.({
        id: userData?.user.id,
        first_name: userData?.user.first_name,
        last_name: userData?.user.last_name,
        email: userData?.user.email || "",
        phone: userData?.user.phone,
        feedback: false,
        created: "",
        updated: "",
        role: userData?.user.role as any,
      });
    }
    setMember?.(
      members?.find(
        (member) => member.id === Number(searchParams.get("member"))
      )
    );

  }, [searchParams.get("member"), loading]);
  useEffect(() => {
    if (isMember) {
      searchParams.set("member", userData?.user.id.toString());
    }
  }, [userData]);

  const progressing = (e: React.MouseEvent) => {
    const { id, value } = e.target as HTMLInputElement;
    if (id === "progress") {
      setProgress(Number(value));
    }
  };
  const defaultDate = moment(
    searchParams.get("date") + " " + searchParams.get("time"),
    "YYYY-MM-DD HH:mm"
  ).format("YYYY-MM-DD HH:mm:ss");

  return (
    <>
      <div className="z2 app_page_layout">
        <div className="app_page_header">
          <h1>{t("registration.create.title")}</h1>
        </div>
        <ScheduleCard progress={progress} progressing={progressing} />
        <>
          {course?.registration_type === "single" ? (
            <EventsCard
              progressButtons
              progressValue={2}
              progress={progress}
              setProgress={setProgress}
              setEvents={setEvents}
              events={events}
              limit={1}
              defaultDate={defaultDate}
            />
          ) : (
            <EventsCard
              progressButtons
              progress={progress}
              progressValue={2}
              setProgress={setProgress}
              setEvents={setEvents}
              events={events}
              defaultDate={defaultDate}
            />
          )}
        </>
        <RegistrationCard progress={progress} setProgress={setProgress} />

        <FetchingCard progress={progress} progressing={progressing} />
      </div>
    </>
  );
};
