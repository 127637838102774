import {Order} from "../../types/orders-types";
import React from "react";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow
} from "../../components/zooza-card-components";
import moment from "moment/moment";
import {lang} from "../../components/language-selector";
import {useTranslation} from "react-i18next";
import {Registration} from "../../types/registration-types";
import {Link} from "react-router-dom";


export const OrderComponent = ({order, registrations}: {order: Order | undefined, registrations: Registration[] | undefined}) => {
    const {t} = useTranslation();
    return (
        <ZoozaCard>
            <ZoozaCardHeader
                icon="icon-transform"
                title={t("order.title.order")}
            />
            <ZoozaCardRow>
                <ZoozaCardCell>
          <span aria-label={t("order.label.product_name")}>
            {t("order.label.product_name")}
          </span>
                    <p>{order?.product.name}</p>
                </ZoozaCardCell>
                <ZoozaCardCell>
          <span aria-label={t("order.label.linked_registration")}>
            {t("order.label.linked_registration")}
          </span>
                    <p>
                        {registrations?.map((registration) =><Link to={"/registrations/"+registration.registration_id }>{registration.registration_id}</Link> )}
                    </p>
                </ZoozaCardCell>
            </ZoozaCardRow>

            <ZoozaCardRow>
                <ZoozaCardCell>
          <span aria-label={t("orders.label.date_purchased")}>
            {t("orders.label.date_purchased")}
          </span>
                    <p>{moment(order?.created).locale(lang).format("LLL")}</p>
                </ZoozaCardCell>
                <ZoozaCardCell>
          <span aria-label={t("orders.label.status")}>
            {t("orders.label.status")}
          </span>
                    <p>{order?.status}</p>
                </ZoozaCardCell>
            </ZoozaCardRow>
            <ZoozaCardActions>{""}</ZoozaCardActions>
        </ZoozaCard>
    )
}
