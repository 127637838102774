export const convertToCurrency = (
  amount: number | string = 0,
  language: string = "en",
  currency?: string
) => {
  let new_currency = "EUR";
  switch (language) {
    case "sk":
      new_currency = "EUR";
      break;
    case "en":
      new_currency = "GBP";
      break;
    case "de":
      new_currency = "EUR";
      break;
    case "cz":
      new_currency = "CZK";
      break;

    case "cs":
      new_currency = "CZK";
      break;
    case "hu":
      new_currency = "HUF";
      break;
    case "pl":
      new_currency = "PLN";
  }

  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency ? currency : new_currency,
  });

  return formatter.format(Number(amount));
};
