import React, { useState } from "react";
import { AppRoles, ZoozaContextTypes } from "../types/types";
import { User, UserData } from "../types/user-types";
export const ZoozaContext = React.createContext({});

export const ZoozaProvider = ({
  children,
}: React.PropsWithChildren<ZoozaContextTypes>) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState(null);
  const [places, setPlaces] = useState(null);
  const [members, setMembers] = useState(null);
  const roles: AppRoles = {
    owner: "owner",
    member: "member",
    main_member: "main_member",
    external_member: "external_member",
    assistant: "assistant",
    receptionist: "receptionist",
    customer: "customer",
    inactive_customer: "inactive_customer",
  };

  const isAlowed = (req_roles: string[], role = userData?.user.role) => {
    return req_roles.includes(String(role));
  };
  const isOwner = isAlowed(["owner"]);
  const isAssistant = isAlowed(["assistant"]);
  const isReceptionist = isAlowed(["receptionist"]);
  const isCustomer = isAlowed(["customer"]);
  const isMember = isAlowed(["member", "main_member", "external_member"]);

  return (
    <ZoozaContext.Provider
      value={{
        userData,
        setUserData,
        loading,
        setLoading,
        courses,
        setCourses,
        setPlaces,
        places,
        members,
        setMembers,
        roles,
        isMember,
        isOwner,
        isAssistant,
        isReceptionist,
        isCustomer,
        isAlowed,
        currency: userData?.company.currency || "EUR",
      }}
    >
      {children}
    </ZoozaContext.Provider>
  );
};
