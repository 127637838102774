import React, { useContext, useEffect, useRef, useState } from "react";
import { DailyOverview } from "../components/calendar-viewer";
import {
  Member,
  Place,
  ZoozaContextTypes,
  CalendarEvent,
  AppRole,
  AxiosErr,
} from "../types/types";
import "../styles/calendar.css";
import { ZoozaContext } from "../services/zooza.context";
import moment, { Moment } from "moment";
import { ZoozaApi } from "../services/Axios";
import { Link, useSearchParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { DateTimePicker } from "../components/date-picker";
import { getTrainers, handleError } from "../services/common-functions";
import { ZoozaSelect } from "../components/inputs";
import { useTranslation } from "react-i18next";
import { DailyOverview2 } from "../components/calendar-lector";
import { Modal } from "../components/modal";
import { TrainerPlacesResponse } from "../types/user-types";
import { DailyAttendance } from "../types/schedule-type";
import { ReactComponent as Loader } from "../assets/img/loader.svg";

export const CalendarPage = () => {
  const { t } = useTranslation();
  const { userData, setLoading, loading, places } =
    useContext<ZoozaContextTypes>(ZoozaContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [calendarData, setCalendarData] = useState<CalendarEvent[]>([]);
  const [date, setDate] = useState<Moment>(moment());
  const [searchParams] = useSearchParams();
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [trainerPLaces, setTrainerPlaces] = useState<Member[]>();
  const [trainerLimits, setTrainerLimits] = useState<TrainerPlacesResponse>();
  const [attendances, setAttendances] = useState<DailyAttendance[]>([]);
  const [trainerLimitsForTrainer, setTrainerLimitsForTrainer] =
    useState<Place>();
  const logedMember = members?.filter(
    (member) => member.id === userData?.user.id
  );
  const [eventsLoading, setEventsLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({
    next: false,
    prev: false,
  });

  const member_roles: AppRole[] = ["member", "external_member"];
  const isMember = member_roles.includes(
    logedMember?.[0]?.role.role as AppRole
  );

  const timeNow = moment().format("HH");
  const columnWidth = 150;

  function scrollToColumn() {
    const div = document.querySelector(".calendar");

    if (div) {
      const currentTime = parseInt(timeNow, 10);
      const targetScrollLeft = currentTime * columnWidth;

      div.scrollLeft = targetScrollLeft;
    }
  }

  useEffect(() => {
    scrollToColumn();
  }, [calendarData]);

  const getCalendarData = async (e_date: Moment) => {
    try {
      const { data } = await ZoozaApi.get(
        `/calendar?start=${moment(e_date).format("YYYY-MM-DD")}&end=${moment(
          e_date
        ).format("YYYY-MM-DD")}`
      );
      setSwitchLoading({ next: false, prev: false });
      return data;
    } catch (err: any) {
      handleError(err.response.data);
      console.error("Fetch Error:", err);
      setSwitchLoading({ next: false, prev: false });
    }
  };

  const getDailyAttendance = async (e_date: Moment) => {
    const place_id = place?.id;
    if (!place_id) {
      return;
    }
    try {
      const { data } = await ZoozaApi.get(
        `/attendance?daily=true&date=${moment(e_date || date).format(
          "YYYY-MM-DD"
        )}&place_id=${place_id}&replacements_only=false&1=1`
      );
      setAttendances(data);
    } catch (err: any) {
      handleError(err.response.data);
    }
  };

  type Response = {
    data: Place[] | Member[];
  };
  const getTrainersPlaces = async () => {
    if (place?.id === undefined) return;
    if (userData?.user.role?.length === 0) return;
    try {
      if (!isMember) {
        const { data } = await ZoozaApi.get(
          `/trainer_places/?place_id=${place_id}`
        );
        setTrainerLimits(data);
        const trainerIds = (data as TrainerPlacesResponse)?.data?.map(
          (trainerPlace) => trainerPlace.trainer_id
        );

        const trainerIdCounts: { [key: number]: number } = {};
        const membersWithPlaces = trainerIds
          ?.map((trainerId) => {
            const member = members?.find(
              (member: Member) => member.id === trainerId
            );
            const trainerPlaces = (data as TrainerPlacesResponse)?.data?.filter(
              (trainerPlace) => trainerPlace.trainer_id === trainerId
            );
            const count = trainerIdCounts[trainerId] || 0;
            trainerIdCounts[trainerId] = count + 1;
            const place = trainerPlaces ? trainerPlaces[count] : null;
            return member ? { ...member, limit: place } : null;
          })
          .filter((member) => member !== null)
          .sort((a, b) => {
            if (!a?.limit?.room_id && b?.limit?.room_id) {
              return -1;
            }
            if (a?.limit?.room_id && !b?.limit?.room_id) {
              return 1;
            }
            if (a?.limit?.room_id && b?.limit?.room_id) {
              return a?.limit?.room_id - b?.limit?.room_id;
            }
            return 0;
          });

        setTrainerPlaces(membersWithPlaces as Member[]);
      } else if (isMember) {
        const { data } = await ZoozaApi.get(`/trainer_places/`);

        const myRoomsWithPlaces: any = [] as any;

        (data as TrainerPlacesResponse)?.data?.forEach((trainerPlace: any) => {
          places?.forEach((place) => {
            if (place.id === trainerPlace.place_id && place.rooms.length > 0) {
              const room = place.rooms.find(
                (room) => room.id === trainerPlace.room_id
              );
              if (room) {
                myRoomsWithPlaces.push({
                  ...room,
                  id: place.id,
                  room_id: room.id,
                  place_id: null,
                });
              } else if (trainerPlace.room_id === 0) {
                myRoomsWithPlaces.push({
                  ...place,
                });
              }
            } else if (place.id === trainerPlace.place_id) {
              myRoomsWithPlaces.push({
                ...place,
                room: null,
                trainer_id: trainerPlace.trainer_id,
              });
            }
          });
        });

        setTrainerLimitsForTrainer(myRoomsWithPlaces);
      }
    } catch (err: any) {
      handleError(err.response.data);
    }
  };

  const fetchData = async () => {
    setEventsLoading(true);
    const responses: Response[] = await Promise.all([
      getCalendarData(date),
      getTrainers(),
    ]);

    setCalendarData(responses[0] as unknown as CalendarEvent[]);
    setMembers(responses[1]?.data as Member[]);
    setEventsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const handleDate = (navigate: number) => {
    if (navigate === 1) setSwitchLoading({ next: true, prev: false });
    if (navigate === -1) setSwitchLoading({ next: false, prev: true });
    const newDate = moment(date).add(navigate, "days");
    getCalendarData(newDate).then((data) => setCalendarData(data));
    getDailyAttendance(newDate);
    setDate(newDate);
  };

  useEffect(() => {
    getTrainersPlaces();
  }, [searchParams.get("place"), members]);
  useEffect(() => {
    getDailyAttendance(date);
  }, [searchParams.get("place")]);

  const place_id = searchParams.get("place") || places?.[0].id;
  const place = places?.find((place) => place.id === Number(place_id));
  const lang = localStorage.getItem("i18nextLng") || "en";
  const handleAddMemberModal = () => {
    setAddMemberModal(!addMemberModal);
  };
  const fullyLoaded =
    !loading &&
    userData?.user.permissions.length !== 0 &&
    places?.length !== 0 &&
    eventsLoading === false;

  return (
    <div className="calendar-page">
      {fullyLoaded ? (
        <>
          <div className="calendar-header">
            <DateTimePicker
              required={false}
              date={moment(date)}
              help={""}
              setDate={setDate}
              format="iiii d.MM.yy"
            />
            {!isMember &&
              trainerLimits &&
              !loading &&
              places?.length !== 0 &&
              !eventsLoading && (
                <ZoozaSelect param={"place"}>
                  {places?.map((place: Place) => (
                    <option key={place.id} value={place.id}>
                      {place.name}
                    </option>
                  ))}
                </ZoozaSelect>
              )}
            {!isMember && (
              <button
                onClick={handleAddMemberModal}
                className=" add_member_button z2 primary"
              >
                {t("add_member.button.add_member")}
              </button>
            )}
          </div>
          <div className="calendar-buttons" style={{ display: "flex" }}>
            <button
              disabled={switchLoading.prev}
              className="z2"
              onClick={(e) => handleDate(-1)}
            >
              {switchLoading.prev ? (
                <Loader className="spinner-button" />
              ) : (
                <em className="icon-arrow-left"></em>
              )}

              {moment(date).subtract(1, "days").locale(lang).format("dddd")}
            </button>
            <div style={{ flex: 0.9 }}>
              <button
                disabled={switchLoading.next}
                className="z2"
                type="submit"
                onClick={() => handleDate(1)}
              >
                {moment(date).add(1, "days").locale(lang).format("dddd")}
                {switchLoading.next ? (
                  <Loader className="spinner-button" />
                ) : (
                  <em className="icon-arrow-right"></em>
                )}
              </button>
            </div>

            <Link
              to="/registrations/create/"
              role="button"
              className="z2 primary"
            >
              {t("global.button.create_registration")}
            </Link>
          </div>
          {!loading && (
            <>
              {!isMember &&
                userData?.user.permissions.length !== 0 &&
                trainerLimits && (
                  <DailyOverview
                    place={place}
                    members={
                      isMember ? logedMember : (trainerPLaces as Member[])
                    }
                    events={
                      isMember
                        ? calendarData.filter(
                            (e) => e.trainer_id === userData?.user.id
                          )
                        : calendarData
                    }
                    handleDate={handleDate}
                    date={date.format("YYYY-MM-DD")}
                    getTrainersPlaces={getTrainersPlaces}
                    attendances={attendances}
                  />
                )}{" "}
              {isMember && userData?.user.role?.length !== 0 && (
                <DailyOverview2
                  places={trainerLimitsForTrainer}
                  events={
                    isMember
                      ? calendarData.filter(
                          (e) => e.trainer_id === userData?.user.id
                        )
                      : calendarData
                  }
                  handleDate={handleDate}
                  date={date.format("YYYY-MM-DD")}
                  attendances={attendances}
                />
              )}
            </>
          )}
          <AddMemberToPlaceModal
            addMemberModal={addMemberModal}
            handleAddMemberModal={handleAddMemberModal}
            place={place}
            members={members}
            getTrainersPlaces={getTrainersPlaces}
          />{" "}
        </>
      ) : (
        <div>
          <div className="calendar-header-skeleton skeleton"></div>

          <div className="daily-overview-skeleton skeleton"></div>
        </div>
      )}
    </div>
  );
};
type AddMemberToPlaceModalProps = {
  addMemberModal: boolean;
  handleAddMemberModal: () => void;
  place: Place | undefined;
  members: Member[];
  getTrainersPlaces: () => void;
};
const AddMemberToPlaceModal = ({
  place,
  members,
  handleAddMemberModal,
  addMemberModal,
  getTrainersPlaces,
}: AddMemberToPlaceModalProps) => {
  const { t } = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState<number>(0);
  const [selectedMember, setSelectedMember] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<any>();
  const handleRoomChange = (
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent
  ) => {
    setSelectedRoom(
      Number((e as React.ChangeEvent<HTMLSelectElement>).target.value)
    );
  };
  const handleMemberChange = (
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent
  ) => {
    setSelectedMember(
      Number((e as React.ChangeEvent<HTMLSelectElement>).target.value)
    );
  };
  useEffect(() => {
    setSelectedMember(members?.[0]?.id);
  }, [members]);
  const onSubmit = async () => {
    setMessage("");
    setError(null);

    setLoading(true);
    try {
      await ZoozaApi.post(`/trainer_places`, {
        place_id: place?.id,
        room_id: selectedRoom,
        trainer_id: selectedMember,
      });
      await getTrainersPlaces();
      setLoading(false);
      setMessage(t("add_member.modal.success.message"));
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (err: AxiosErr | any) {
      handleError(err.response.data, "alert");
      setLoading(false);
      setError(err);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  return (
    <Modal
      title={t("add_member.modal.title")}
      isOpen={addMemberModal}
      onClose={handleAddMemberModal}
      submitButton={t("add_member.modal.button.submit")}
      onSubmit={onSubmit}
      cancelButton={t("add_member.modal.button.cancel")}
      loading={loading}
      errors={error?.response?.data}
    >
      <div className="add_trainer_modal">
        <h4 style={{ textAlign: "center" }}>
          {t("add_member.modal.place")} <br />
          {place?.name}
        </h4>
        {place?.rooms.length !== 0 && (
          <ZoozaSelect
            value={selectedRoom}
            onChange={handleRoomChange}
            param={""}
            label={t("add_member.modal.select_room")}
          >
            <option onClick={() => setSelectedRoom(0)}>{place?.name}</option>
            {place?.rooms.map((room) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </ZoozaSelect>
        )}
        <ZoozaSelect
          value={selectedMember}
          onChange={handleMemberChange}
          param={""}
          label={t("add_member.modal.select_member")}
        >
          {members?.map((member) => (
            <option key={member.id} value={member.id}>
              {member.first_name} {member.last_name} ({member.email})
            </option>
          ))}
        </ZoozaSelect>
        <p style={{ color: "green" }}>{message}</p>
      </div>
    </Modal>
  );
};
