import React, { useEffect, useState } from "react";
import moment from "moment";
import { CalendarEvent, Member, Place, Room } from "../types/types";
import "../styles/calendar-lector.css";
import { DailyAttendance } from "../types/schedule-type";
import { CalendarEventItem } from "./event-item-lector";
import { useNavigate } from "react-router-dom";
import { ZoozaApi } from "../services/Axios";
import { TrainerPLace } from "../types/user-types";

export const DailyOverview2 = ({
  places,
  events,
  date,
  attendances,
}: {
  places: Place[] | undefined | any;
  events: CalendarEvent[] | undefined;
  handleDate: (num: number) => void;
  date: string;
  attendances: DailyAttendance[] | undefined;
}) => {
  const navigate = useNavigate();
  const times = Array.from(Array(24).keys()).map((hour) => {
    const time = moment().hour(hour).minute(0).format("HH:mm");
    return {
      id: hour,
      time: time,
    };
  });

  const filteredEvents = events?.filter((event) =>
    places?.map((place: any) => place.id)?.includes(event.place_id)
  );

  return (
    <div className="calendar">
      <div className="calendar2-header"></div>
      <div className="calendar2-body">
        <ul>
          {filteredEvents?.map((event: any) => (
            <CalendarEventItem
              attendances={attendances}
              key={event?.id}
              event={event}
              places={places}
              times={times}
            />
          ))}
        </ul>
        <div className="extraTime">
          <ul>
            <div style={{ height: 60 }}></div>
            {times?.map((time) => (
              <div className="time2-slot">
                <div className="time2-cell">{time.time}</div>
              </div>
            ))}
          </ul>
        </div>
        <div className="calendar2-lectors">
          {places?.map((room: any) => (
            <React.Fragment key={room.id}>
              <ul>
                <li
                  className="place2-separator"
                  style={{ position: "sticky", top: 0, zIndex: 100 }}
                >
                  {room.name}
                </li>
                {times?.map((time) => (
                  <li className="newLi" key={time.time}></li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
