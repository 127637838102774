import React, { Ref, forwardRef } from "react";

type InputProps = {
  value?: string | number | readonly string[] | undefined;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string | any;
  id?: string;
  label?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef(
  (
    {
      value,
      onClick,
      onChange,
      type,
      placeholder,
      id,
      label,
      onBlur,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <div  className="z2 filter_item" >
        <div className="z2 filter_control type_input">
          <input
            style={{ fontSize: 16, textAlign: "center", height: 33}}
            type={type}
            id={id}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder={placeholder || ""}
          />
        </div>
      </div>
    );
  }
);
