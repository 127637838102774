import React, { useContext, useEffect, useState } from "react";
import { LanguageSelector } from "../../components/language-selector";
import { ZoozaApi } from "../../services/Axios";
import { ZoozaContext } from "../../services/zooza.context";
import { useSearchParams } from "react-router-dom";
import {  ZoozaContextTypes } from "../../types/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { RecentEventsMembersTable } from "./recent-events.members-table";
import { MyUpcomingEvents } from "./my-upcoming-events-table";
import { PaymentsTable } from "./payments";

const today = moment().format("YYYY-MM-DD");
const nextMonth = moment().clone().add(1, "months").format("YYYY-MM-DD");
export const HomePage = () => {
  const [lectorEvents, setLectorEvents] = useState<any>();
  const { isAlowed, members } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const findLectorEvents = async () => {
    const memberParam = searchParams.get("member");
    console.log(memberParam);
    if (!memberParam) return; // If memberParam is falsy, exit early
    try {
      const { data } = await ZoozaApi.get(
        `/users/${memberParam}/report?start=${today}&end=${nextMonth}&unplanned=false&1=1`
      );
      setLectorEvents(data.events);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!searchParams.get("member") || !members) {
      return;
    } else {
      findLectorEvents();
    }
  }, [searchParams.get("member")]);
  useEffect(() => {
    if (members?.length !== 0) {
      searchParams.set("member", String(members?.[0].id));
    }
  }, [members]);

  return (
    <>
      <div className="body">
        <div className="app_page_header">
          <h1 style={{ marginBottom: "20px" }}>
            {t("dashboard.title.welcome")}
          </h1>
        </div>
        <div className="z2 app_page_layout">
          {!isAlowed?.(["receptionist"]) && <MyUpcomingEvents />}
          {isAlowed?.(["owner", "receptionist", "assistant"]) && (
            <>
              <PaymentsTable />
            </>
          )}
          {isAlowed?.(["owner", "receptionist", "assistant"]) && (
            <RecentEventsMembersTable lectorEvents={lectorEvents} />
          )}
        </div>
        <LanguageSelector></LanguageSelector>
      </div>
    </>
  );
};
