import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ZoozaContextTypes } from "../../types/types";
import { AttendanceEvent, Registration } from "../../types/registration-types";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardCell,
  ZoozaCardHeader,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaApi } from "../../services/Axios";
import moment from "moment";
import { EventDetail, ScheduleDetailResponse } from "../../types/schedule-type";
import { AttendanceManager } from "../../components/attendance-manager";
import { AddPaymentModal } from "../../components/add-payment-modal";
import { handleError, msg } from "../../services/common-functions";
import "../event-page.css";
import { Modal } from "../../components/modal";
import { DateTimeCard } from "./date-time-card";
import { EventPageNotes } from "./event-notes";
import {t} from "i18next";
import { convertToCurrency } from "../../services/curency-service";
import {Loading} from "../../components/loading";

export const EventPage = () => {
  const {
    setLoading,
    places = [],
    courses,
    members,
      userData
  } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { id } = useParams();
  const location = useLocation();
  const [event, setEvent] = useState<EventDetail>();

  const [attendance, setAttendance] = useState<AttendanceEvent[]>();
  const [schedules, setSchedules] = useState<ScheduleDetailResponse>();
  const [open, setOpen] = React.useState(false);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    show: boolean;
    userId?: number;
    regIds?: number;
    eventId?: number;
  }>({ show: false });
  const [showConfirmationModal2, setShowConfirmationModal2] = useState<{
    show: boolean;
    userId?: number;
    regId?: number;
    eventId?: number;
  }>({ show: false });

  const [selected_ids, setSelectedIDS] = useState<{
    user_id: number | undefined;
    registration_id: number | undefined;
  } | null>(null);

  const [agreementOption, setAgreementOption] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);

  const handleAgreementChange = async (
    option: string,
    eventId: number | undefined
  ) => {
    setAgreementOption(option);
    if (option === "payNow") {
      setOpen(true);
      //await handleSubmit();
      setShowConfirmationModal2({ show: false });
    }
  };
  const handleSubmit = async () => {
    setSmallLoader(true);
    try {
      // Update the attendance status
      await ZoozaApi.put(`/registrations/${selected_ids?.registration_id}`, {
        action: "set_attendance",
        attendance: "going",
        user_id: selected_ids?.user_id,
        event_id: event?.id,
        use_voucher: false,
      });

      // Close the modal
      setOpen(false);
      setSmallLoader(false);
    } catch (error) {
      setSmallLoader(false)
      console.error("Error updating attendance:", error);
    }
  };

  useEffect(() => {
    getAttendance();
  }, [schedules]);

  const handleConfirmation = async (applyCredit: boolean, eventId: number) => {
    setShowConfirmationModal({ show: false });
    setSmallLoader(true);
    if (applyCredit) {
      try {
        await ZoozaApi.put(`/registrations/${selected_ids?.registration_id}`, {
          action: "set_attendance",
          attendance: "going",
          user_id: selected_ids?.user_id,
          event_id: eventId,
          use_voucher: applyCredit,
        });

        setSchedules((prevSchedules) => {
          if (!prevSchedules) return prevSchedules;
          const updatedEvents = prevSchedules.events?.map((event) => {
            if (Number(event.id) === Number(eventId)) {
              return { ...event, __calc__attendance__going: 1 };
            }
            return event;
          });
          return { ...prevSchedules, events: updatedEvents };
        });
        setSmallLoader(false);
      } catch (error) {
        console.error("Error updating attendance:", error);
        setSmallLoader(false);
      }
    } else {
      setShowConfirmationModal2({
        show: true,
        userId: selected_ids?.user_id,
        regId: selected_ids?.registration_id,
        eventId: Number(eventId),
      });
    }
  };

  const handleButtonClick2 = async (
    id: number | undefined,
    r_id: number | undefined,
    user_id: number | undefined
  ) => {
    setSmallLoader(true);
    setSelectedIDS({ registration_id: r_id, user_id });
    const { data } = await ZoozaApi.get(
      `/credits?types=entrance_voucher&usage=not_used&origin_user_id=${r_id}&1=1`
    );
    if (
      data?.data?.some((voucher: { active: boolean }) => voucher.active)
    ) {
      setShowConfirmationModal({
        show: true,
        userId: user_id,
        regIds: r_id,
        eventId: id,
      });
    } else {
      setShowConfirmationModal2({
        show: true,
        userId: user_id,
        regId: r_id,
        eventId: id,
      });
    }
    setSmallLoader(false);
  };
  const handleConfirmation2 = async (
    agree: boolean,
    id: number,
    userId: any[],
    regIds: number[]
  ) => {
    setSmallLoader(true);
    setShowConfirmationModal2({ show: false });
    if (agree) {
      try {
        await ZoozaApi.put(`/registrations/${regIds}`, {
          action: "set_attendance",
          attendance: "going",
          user_id: userId[0],
          event_id: id,
          use_voucher: false,
        });

        setSchedules((prevSchedules) => {
          if (!prevSchedules) return prevSchedules;
          const updatedEvents = prevSchedules.events?.map((event) => {
            if (event.id === id) {
              return { ...event, __calc__attendance__going: 1 };
            }
            return event;
          });
            setSmallLoader(false);
          return { ...prevSchedules, events: updatedEvents };
        });
      } catch (error) {
        setSmallLoader(false);
        console.error("Error updating attendance:", error);
      }
    }
  };
  const handleConfirmationClick = (
    applyCredit: boolean,
    eventId: number | undefined
  ) => {
    if (eventId !== undefined) {
      handleConfirmation(applyCredit, eventId);
      setShowConfirmationModal({ show: false });
    } else {
      console.error("Event ID is undefined");
    }
  };
  const eventCourse = courses?.find((course) => course.id === event?.course_id);
  const getEvent = async () => {
    setLoading?.(true);
    try {
      const { data } = await ZoozaApi.get(`events/${id}`);
      setEvent(data);
      setLoading?.(false);
    } catch (error: any) {
      handleError(error.response.data);
      setLoading?.(false);
    }
  };
  const getAttendance = async () => {
    try {
      const { data: attendance } = await ZoozaApi.get(
        `/attendance?event_id=${id}`
      );

      setAttendance(attendance);
    } catch (error: any) {
      console.error("Failed to fetch attendance:", error);
    }
  };

  const getRegGroup = async () => {
    const { data } = await ZoozaApi.get(
      `/registrations?advanced_search=1&schedule_id=${event?.schedule_id}&status=active`
    );
    setRegistrations(matchRegistrationsWihAttendance(data.results));
  };

  const getSchedule = async () => {
    try {
      const { data } = await ZoozaApi.get(`/schedules/${event?.schedule_id}`);
      setSchedules(data);
    } catch (error: any) {
      handleError(error.response.data);
      throw error;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([getEvent(), getAttendance()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    if (event?.schedule_id) {
      getSchedule();
    }
    if (eventCourse?.registration_type === "open") {
      getRegGroup();
    }
  }, [event?.schedule_id, eventCourse?.registration_type, event, !open]);

  const upComingEvent = schedules?.events?.find((e) =>
    moment(e?.date).isAfter(event?.date)
  );
  const pastEvent = schedules?.events?.find((e) =>
    moment(e?.date).isBefore(event?.date)
  );

  const matchRegistrationsWihAttendance = (results: Registration[]) => {
    return results.map((reg: any) => {
      const matchedAttendance = attendance?.find(
        (att) => Number(att.registration_id) === Number(reg.registration_id)
      );
      if (matchedAttendance) {
        return { ...reg, attendance: matchedAttendance };
      } else {
        return reg;
      }
    });
  };

  return (
    <>
      <div className="z2 app_page_layout">
        <div className="z2 app_page_toolbar">
          <Link
            to={
              pastEvent !== undefined
                ? `/calendar/${pastEvent?.id}`
                : location.pathname
            }
            role="button"
            className={"z2 " + (pastEvent === undefined ? " disabled" : "")}
          >
            {t("event.button.past_event") +
              " " +
              moment(pastEvent?.date).format("DD.MM.YYYY")}
          </Link>
          <Link
            to={
              upComingEvent !== undefined
                ? `/calendar/${upComingEvent?.id}`
                : location.pathname
            }
            role="button"
            className={"z2" + (upComingEvent === undefined ? " disabled" : "")}
          >
            {t("event.button.next_event") +
              " " +
              moment(upComingEvent?.date).format("DD.MM.YYYY")}
          </Link>
        </div>
        <div className="app_page_column span_1">
          <DateTimeCard
            event={event}
            setEvent={setEvent}
            members={members}
            places={places}
            schedules={schedules}
          />
        </div>

        <ZoozaCard>
          <ZoozaCardHeader
            title={t("global.title.attendance")}
            icon="icon-tick-2"
          ></ZoozaCardHeader>
          {(!eventCourse ) ? <Loading /> : eventCourse?.registration_type === "open"
            ? registrations.map((reg, i) => {
                return (
                  <AttendanceOpenCourse
                    key={i}
                    event={event}
                    reg={reg}
                    handleButtonClick2={handleButtonClick2}
                    getAttendance={getAttendance}
                    setOpen={setOpen}
                  />
                );
              })
            : attendance?.map((a, i) => {
                return (
                  <AttendanceRow attendance={a} getAttendance={getAttendance} />
                );
              })}

          <Modal
            submitButton="Yes"
            cancelButton="No"
            buttonDisabled={smallLoader}
            title={t("event.title.want_use_voucher")}
            isOpen={showConfirmationModal.show}
            onClose={() =>
              handleConfirmationClick(false, showConfirmationModal.eventId)
            }
            onSubmit={() =>
              handleConfirmationClick(true, showConfirmationModal.eventId)
            }
            loading={smallLoader}
          >
            <p>
              This client has available entrances from the entry pass. Do you
              want to apply an Entry type credit for this lesson/event? By
              clicking the No button, the client will pay the lesson/event
              individually (in cash,...).
            </p>
          </Modal>

          <ZoozaCardActions>{""}</ZoozaCardActions>
        </ZoozaCard>
        <EventPageNotes event={event} />
      </div>

      <AddPaymentModal
        open={open}
        setOpen={setOpen}
        order_id={selected_ids?.registration_id}
        type="payment"
        order_type={"course"}
        onclick={handleSubmit}
        default_value={schedules?.price}

      />
      <Modal
        submitButton={t("global.button.yes")}
        cancelButton={t("global.button.no")}
        buttonDisabled={false}
        title={t("event.title.want_use_voucher")}
        isOpen={showConfirmationModal2.show}
        onClose={() => {
          if (showConfirmationModal2.eventId !== undefined) {
            handleConfirmation2(
              false,
              showConfirmationModal2.eventId,
              registrations?.map((reg) => reg.user_id) || [],
              registrations?.map((reg) => reg.registration_id) || []
            );
            setShowConfirmationModal2({ show: false });
          } else {
            console.error("Event ID is undefined");
          }
        }}
        onSubmit={() => {
          if (agreementOption === "payLater") {
            if (showConfirmationModal2.eventId !== undefined) {
              handleConfirmation2(
                true,
                showConfirmationModal2.eventId,
                registrations?.map((reg) => reg.user_id) || [],
                registrations?.map((reg) => reg.registration_id) || []
              );
              setShowConfirmationModal2({ show: false });
            }
          }
        }}
        loading={smallLoader}
      >
        <div >
          <p className="modal-text">
            {t("event.title.this_event_not_free")}
          </p>
          <div className="agreement-options">
            <label>
              <input
                type="radio"
                name="agreement"
                value="payLater"
                onChange={(e) =>
                  handleAgreementChange(
                    e.target.value,
                    showConfirmationModal2.eventId
                  )
                }
              />
              {t("event.label.pay_later") }
            </label>
            <label>
              <input
                type="radio"
                name="agreement"
                value="payNow"
                onChange={(e) =>
                  handleAgreementChange(
                    e.target.value,
                    showConfirmationModal2.eventId
                  )
                }
              />
              {t("event.label.pay_now")}
            </label>
          </div>  {agreementOption === "payLater" && (
            <p>
              {t("event.title.pay_later", { total: convertToCurrency(Number(schedules?.price ),userData?.company.region,  userData?.company.currency) })}

            </p>
        )}
        </div>

      </Modal>
    </>
  );
};

const AttendanceOpenCourse = ({
  event,
  reg,
  handleButtonClick2,
  getAttendance,
  setOpen,
}: {
  event: EventDetail | undefined;
  reg: any;
  handleButtonClick2: (
    event_id: number | undefined,
    registration_id: number | undefined,
    user_id: number | undefined
  ) => void | any;
  getAttendance: () => void | any;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <ZoozaCardRow>
      <ZoozaCardCell>
        <span aria-label={t("event.title.client_name")}>
          {t("event.title.client_name")}
        </span>
        <span>
          <Link className="z2" to={`/registrations/${reg.registration_id}`}>
            {reg.full_name}
            <br />
            {reg.email}
            <br />
            {reg.phone}
          </Link>
        </span>
      </ZoozaCardCell>
      <ZoozaCardCell>
        <span aria-label={t("global.title.payment")}>
          {t("global.title.payment")}
        </span>
        {msg(reg?.payment_status)}

        <div>
          <button onClick={() => setOpen(true)} className="z2 primary">
            {t("global.button.add_payment")}
          </button>
        </div>
      </ZoozaCardCell>
      {!reg?.attendance?.attendance ? (
        <ZoozaCardCell>
          <span aria-label={t("global.title.attendance")}>
            {t("global.title.attendance")}
          </span>
          <div>
            <button
              onClick={() =>
                handleButtonClick2(
                  event?.id,
                  reg?.registration_id,
                  reg?.user_id
                )
              }
              className="z2 primary"
            >
              {t("global.button.sign_in")}
            </button>
          </div>
        </ZoozaCardCell>
      ) : (
        <ZoozaCardCell>
          <span aria-label={t("global.title.attendance")}>
            {t("global.title.attendance")}
          </span>
          <AttendanceManager
            registration_id={reg?.attendance?.registration_id}
            status={reg?.attendance?.attendance}
            user_id={reg?.attendance?.user_id}
            getAttendance={() => getAttendance()}
          />
        </ZoozaCardCell>
      )}
    </ZoozaCardRow>
  );
};

const AttendanceRow = ({
  attendance,
  getAttendance,
}: {
  attendance: AttendanceEvent;
  getAttendance: () => void;
}) => {
  return (
    <ZoozaCardRow>
      <ZoozaCardCell>
        <span aria-label={t("event.title.client_name")}>
          {t("event.title.client_name")}
        </span>
        <span>
          <Link
            className="z2"
            to={`/registrations/${attendance.registration_id}`}
          >
            {attendance.full_name}
            <br />
            {attendance.email}
            <br />
            {attendance.phone}
          </Link>
        </span>
      </ZoozaCardCell>
      <ZoozaCardCell>
        <span aria-label={t("global.title.payment")}>
          {t("global.title.payment")}
        </span>
        {msg(attendance.payment_status)}
      </ZoozaCardCell>
      <ZoozaCardCell>
        <span aria-label={t("global.title.attendance")}>
          {t("global.title.attendance")}
        </span>
        <AttendanceManager
          registration_id={attendance?.registration_id}
          status={attendance.attendance}
          user_id={attendance?.user_id}
          getAttendance={() => getAttendance()}
        />
      </ZoozaCardCell>
    </ZoozaCardRow>
  );
};
