import {Order} from "../../types/orders-types";
import React from "react";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

export const OrderRow = ({order}: { order: Order }) => {
    const {t} = useTranslation();
    return (<>
        <div className="registration_card_start">
            <div className="status">
                <h5>{t("orders.label.type")}</h5>
                <span>{t("orders.label.product")}</span>
                <div>
                    <small style={{fontSize: 12}}>{order.id}</small>
                </div>
            </div>
            <div className="name">
                <h5>{t("orders.label.customer")}</h5>
                <Link to={order.id.toString()}>
                    <span>
                         {order.__users__full_name?order.__users__full_name: order.user.first_name + order.user.last_name  || t("orders.label.noname")}
                    </span>

                </Link> <br/>
                <span className="small">{order.__users__email} <br/>{order.__users__phone}</span>
            </div>
        </div>
        <div className="registration_card_end">
            <div className="payment">
                <h5>{t("orders.label.payment")}</h5>
                <span>{order.__calc__paid}</span>
            </div>
            <div className="attendance">
                <h5>{t("orders.label.product")}</h5>
                <span>{order.product.name}</span>
            </div>
            <div className="note">
                <h5>{t("orders.label.date_purchased")}</h5>
                <span>{order.created.toString()}</span>
            </div>
        </div>
    </>)
}
