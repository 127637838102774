import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes, Member } from "../../types/types";
import { ZoozaSelect } from "../../components/inputs";
import i18n from "../../locale";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  ZoozaTable,
  ZoozaTableBody,
  ZoozaTableCell,
  ZoozaTableHeader,
  ZoozaTablePagination,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../../components/zooza-table-components";
export const RecentEventsMembersTable = ({
  lectorEvents,
}: {
  lectorEvents: any;
}) => {
  const lang = i18n.language;
  const { members } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { t } = useTranslation();
  return (
    <ZoozaTable>
      <ZoozaTableHeader>
        <div className="results_found">
          <h3>
            {" "}
            <em className="icon-hourglass"></em>{" "}
            {t("dashboard.trainer_events.title")}
          </h3>
        </div>

        {
          <ZoozaSelect param={"member"}>
            {members?.map((lector: Member) => (
              <option key={lector.id} value={lector.id}>
                {`${lector.first_name} ${lector.last_name}`}
              </option>
            ))}
          </ZoozaSelect>
        }
      </ZoozaTableHeader>
      <ZoozaTableBody>
        {lectorEvents &&
          lectorEvents?.map((lectorEvent: any) => (
            <ZoozaTableRow key={lectorEvent.id}>
              <ZoozaTableRowItem>
                <ZoozaTableCell span="5">
                  <span aria-label={t("global.title.date")}>
                    {t("global.title.date")}
                  </span>
                  {moment(lectorEvent.date_start).locale(lang).format("LLLL")}
                </ZoozaTableCell>
                <ZoozaTableCell span="5">
                  <span aria-label={t("global.title.course")}>
                    {t("global.title.course")}
                  </span>
                  {lectorEvent.course_name}
                </ZoozaTableCell>
                <ZoozaTableCell span="2">
                  <Link to={"calendar/" + lectorEvent.event_id}>
                    {t("dashboard.trainer_events.view")}
                  </Link>
                </ZoozaTableCell>{" "}
              </ZoozaTableRowItem>
            </ZoozaTableRow>
          ))}{" "}
      </ZoozaTableBody>
      <ZoozaTablePagination></ZoozaTablePagination>
    </ZoozaTable>
  );
};
