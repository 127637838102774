import {ScheduleProductsType} from "../../types/schedule-type";
import {Registration} from "../../types/registration-types";
import React, {useContext, useEffect, useState} from "react";
import {ZoozaContextTypes} from "../../types/types";
import {ZoozaContext} from "../../services/zooza.context";
import {useTranslation} from "react-i18next";
import {ZoozaApi} from "../../services/Axios";
import {Modal} from "../../components/modal";
import {msg} from "../../services/common-functions";
import {convertToCurrency} from "../../services/curency-service";

export const OrderPurchaseModal = ({open, setOpen, product, registration, getOrders}: {
    open: boolean,
    setOpen: (open: boolean) => void,
    product: ScheduleProductsType | undefined,
    registration: Registration | undefined,
    getOrders: () => void
}) => {
    const {userData} = useContext<ZoozaContextTypes>(ZoozaContext);
    const [method, setMethod] = useState<string>("cash")
    const [value, setValue] = useState<number>(0)
    const {t} = useTranslation();
    const buyProduct = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault(); // prevent the default action of the link
        const user = await getUser(registration?.user_id)


        try {
            await ZoozaApi.post(
                     `orders`,
                     {
                         product_id: product?.product_id,
                         payment_method: method,
                         payment_method_transfer_options: "",
                         registration_id: registration?.id,
                         user: {
                             email: registration?.__users__email,
                             id: registration?.user_id,
                             first_name: user?.first_name,
                             last_name: user?.last_name
                         },
                     })
                 setOpen(false)
                 getOrders()

        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        setValue(product?.product.__calc__minimum_price || 0)


    }, [product])
 const getUser = async (id: number | undefined) => {
        try {
            const {data: user} = await ZoozaApi.get(`/users/${id}`)
            return user
        } catch (e) {
            console.log(e)
        }
 }

    return (
        <Modal onSubmit={buyProduct} submitButton={t("global.button.ok")} cancelButton={t("global.button.cancel")}
               isOpen={open} onClose={() => setOpen(false)} title={t(product?.product.name ? product.product.name : "global.title.select_payment_method")}>
            <form style={{display: "grid", padding: 10}} action="">

                <fieldset className="radio_group">
                    <legend>{t("global.payment.title.method")}</legend>
                    <ul>
                        <li>
                            <label htmlFor="cash">
                                <input
                                    type="radio"
                                    name="cash"
                                    value={method}
                                    checked={method === "cash"}
                                    onChange={() => setMethod("cash")}
                                    onClick={() => setMethod("cash")}
                                    id="credit"
                                />
                                {t("modal.add_payment.select.cash")}
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                name="CREDIT_VIA_TRANSFER"
                                value={method}
                                checked={method === "CREDIT_VIA_TRANSFER"}
                                onChange={() => setMethod("CREDIT_VIA_TRANSFER")}
                                onClick={() => setMethod("CREDIT_VIA_TRANSFER")}
                                id="CREDIT_VIA_TRANSFER"
                            />
                            <label htmlFor="CREDIT_VIA_TRANSFER">
                                {t("modal.add_payment.select.credit_via_transfer")}
                            </label>
                        </li>
                    </ul>
                </fieldset>
                <br/>

                <>

                    <br/>

                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",

                            alignItems: "center",
                        }}
                    >
                        <h4 style={{marginRight: 10}}>{t("global.title.method")}: </h4>{" "}
                        <span> {msg(method)}</span>


                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",

                            alignItems: "center",
                        }}
                    >
                        <h4 style={{marginRight: 10}}>{t("global.title.price")}: </h4>{" "}
                        <span> {convertToCurrency(product?.product.__calc__minimum_price, userData?.company.region)}</span>


                    </div>
                </>


                <br/>


                <br/>
            </form>

        </Modal>
    )
}
