import React from "react";

import { RegistrationResult } from "../../types/registration-types";
import { ZoozaContextTypes } from "../../types/types";
import moment from "moment";
import "moment/min/locales";

import { Link } from "react-router-dom";
import {
  ZoozaTableRow,
  ZoozaTableRowItem,
  ZoozaTableCell,
} from "../../components/zooza-table-components";
import { convertToCurrency } from "../../services/curency-service";
import { ZoozaContext } from "../../services/zooza.context";
import { PaymentIcon } from "../../components/payment-status";
import { useTranslation } from "react-i18next";

export const RegistrationRow = ({
  registration,
  setOpen,
  setRegistration,
}: {
  registration: RegistrationResult | undefined;

  setOpen: (open: boolean) => void;
  setRegistration: (registration: RegistrationResult | undefined) => void;
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "en";
  const { userData, places } =
    React.useContext<ZoozaContextTypes>(ZoozaContext);

  const schedule = registration?.course?.schedules?.[0];

  const futureEvent = registration?.course?.events?.find((e) =>
    moment(e.date, "YYYY-MM-DD HH:mm:ss").isAfter(moment())
  );
  const pastEvent = registration?.course?.events?.find((e) =>
    moment(e.date, "YYYY-MM-DD HH:mm:ss").isBefore(moment())
  );
  let color = "";
  if (Math.sign(Number(registration?.payment_balance)) === 0) {
    color = "num_neutral";
  } else if (Math.sign(Number(registration?.payment_balance)) === 1) {
    color = "num_positive";
  } else if (Math.sign(Number(registration?.payment_balance)) === -1) {
    color = "num_negative";
  }

  return (
    <>
      <ZoozaTableRow>
        <ZoozaTableRowItem>
          <ZoozaTableCell span="2">
            <p>{registration?.registration_id}</p>
          </ZoozaTableCell>

          <ZoozaTableCell span="5">
            <span aria-label={t("global.title.name")}>
              {t("global.title.name")}
            </span>
            <div>
              <p>
                <Link to={String(registration?.registration_id)}>
                  {registration?.full_name}{" "}
                </Link>
              </p>
              <p>
                {registration?.email}, {registration?.phone}
              </p>
            </div>
          </ZoozaTableCell>

          <ZoozaTableCell span="5">
            <span
              aria-label={t("registartions.title.course_name_schedule_name")}
            >
              {t("registartions.title.course_name_schedule_name")}
            </span>
            <div>
              <p>
                {registration?.course?.name}{" "}
                {futureEvent
                  ? moment(futureEvent?.date)
                      .locale(lang)
                      .format("dddd LL HH:mm")
                  : moment(pastEvent?.date)
                      .locale(lang)
                      .format("dddd LL HH:mm")}
              </p>
            </div>

            <div>
              <p>
                {schedule?.place}, {schedule?.trainer},{" "}
                {moment(schedule?.start).isValid()
                  ? t("registartions.title.schedule_started") +
                    " " +
                    moment(schedule?.start).format("DD.MM.YYYY")
                  : t("registartions.title.schedule_not_started")}
              </p>
              {/* <p> {place?.name}</p> */}
            </div>
          </ZoozaTableCell>
        </ZoozaTableRowItem>

        <ZoozaTableRowItem>
          <ZoozaTableCell span="2">
            <span aria-label={t("registartions.title.payment")}>
              {t("registartions.title.payment")}
            </span>
            <PaymentIcon payment_status={registration?.payment_status} />
          </ZoozaTableCell>

          <ZoozaTableCell span="2">
            <span aria-label={t("registartions.title.paid")}>
              {t("registartions.title.paid")}
            </span>
            <p>
              {convertToCurrency(
                registration?.__calc__paid,
                userData?.company.region
              )}
            </p>{" "}
          </ZoozaTableCell>

          <ZoozaTableCell span="2">
            <span aria-label={t("registartions.title.debt")}>
              {t("registartions.title.debt")}
            </span>
            <p>
              {convertToCurrency(
                registration?.payment_debt,
                userData?.company.region
              )}
            </p>
          </ZoozaTableCell>
          <ZoozaTableCell span="2">
            <span aria-label={t("registartions.title.balance")}>
              {t("registartions.title.balance")}
            </span>
            <span className={color}>
              {convertToCurrency(
                registration?.payment_balance,
                userData?.company.region,
                userData?.company.currency
              )}
            </span>
          </ZoozaTableCell>
          <ZoozaTableCell span="4">
            <div>
              <button
                onClick={() => {
                  return setOpen(true), setRegistration(registration);
                }}
                className="z2 primary"
              >
                {t("registartions.button.add_payment")}
              </button>
            </div>
          </ZoozaTableCell>
        </ZoozaTableRowItem>
      </ZoozaTableRow>
    </>
  );
};
