import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Loader} from "../../assets/img/loader.svg";

type PaymentStatus = "any" | "paid" | "unpaid";

export const Aside = ({setSearch, search, setPaymentStatus, paymentStatus, loading}: {
    search: string,
    setSearch: (search: string) => void,
    paymentStatus: PaymentStatus,
    setPaymentStatus: (paymentStatus: PaymentStatus) => void
    loading: boolean
}) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearch(e.target.value);
    }
    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setPaymentStatus(e.target.value as PaymentStatus);
    }
    const {t} = useTranslation();
    return (
        <aside>
            <section>
                <form>
                    <div className="input_text">
                        <label htmlFor="name">
                            <span>{t("orders.label.product_name")}</span>

                            <input type="text"
                                   id="name" name="name" onChange={onChange} value={search}
                                   placeholder={t("orders.placeholder.search")}/>

                        </label>
                        <small className="block_help">{t("orders.label.help_text")}</small>
                    </div>
                    <div className="input">
                        <label>
                            <span>{t("orders.label.filter")}</span>
                            <br/>
                            <select className="select-css"
                                    name=""
                                    onChange={onSelectChange}
                                    value={paymentStatus}>
                                <option value="any">{t("orders.option.all")}</option>
                                <option value="paid">{t("orders.option.paid")}</option>
                                <option value="unpaid">{t("orders.option.unpiad")}</option>
                            </select>
                        </label>
                        <span className="block_help">
                            {loading && <Loader className="spinner-button"/>}
                    </span>
                    </div>
                    <hr/>

                </form>
            </section>
        </aside>
    )
}
