import React, { useContext, useEffect, useState } from "react";
import { Modal } from "../modal";

import { EventReqType } from "./events-card";
import { ZoozaApi } from "../../services/Axios";
import { DateTimePicker } from "../date-picker";
import { convertToCurrency } from "../../services/curency-service";
import { handleError, msg } from "../../services/common-functions";
import { InputNumber } from "../add-payment-modal";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import moment from "moment";
import { useTranslation } from "react-i18next";

type ModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  paid: number | undefined;
  oldEvents: EventReqType[] | undefined;
  events: EventReqType[] | undefined;
  toPay: number | undefined;
  order_id: number | undefined;
  getEvents?: () => void;
};
export const AddEventsModal = ({
  open,
  setOpen,
  paid,
  events,
  toPay,
  oldEvents,
  order_id,
  getEvents,
}: ModalProps) => {
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState("credit");
  const [paymentDate, setPaymentDate] = useState(moment());
  const [note, setNote] = useState("");
  const [courseValue, setCourseValue] = useState(0);
  const [paidValue, setPaidValue] = useState(0);
  const { userData, courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [loading, setLoading] = useState(false);
  const [paidWhole, setPaidWhole] = useState(true);
  const course = courses?.find((course) => course.id === events?.[0].course_id);
  const FullCourseFee =
    Number(course?.price) + Number(course?.registration_fee);
  const fullRegType = course?.registration_type === "full2";
  const onClose = () => {
    setOpen(false);
  };
  const addPayments = async () => {
    if (events) {
      try {
        const { data } = await ZoozaApi.post(`/payments`, [
          {
            order_id,
            amount: paidWhole ? courseValue : paidValue,
            transaction_type: paymentType,
            variable_symbol: order_id,
            posting_date: moment().format("YYYY-MM-DD"),
            value_date: moment(paymentDate).format("YYYY-MM-DD"),
            note,
            origin: "manual",
            order_type: "course",
          },
          {
            order_id,
            amount: courseValue,
            transaction_type: "course_payment",
            variable_symbol: order_id,
            posting_date: moment().format("YYYY-MM-DD"),
            value_date: moment(paymentDate).format("YYYY-MM-DD"),
            note,
            origin: "manual",
            order_type: "course",
          },
        ]);
      } catch (e: any) {
        handleError(e.response.data, "alert");
      }
    }
  };
  const onSubmit = async () => {
    if (
      events?.filter(
        (event: EventReqType) =>
          event.trainer_id &&
          event.place_id &&
          event.date &&
          event.time &&
          event.trainer_id !== 0 &&
          event.place_id !== 0
      ).length
    ) {
      setLoading(true);
      await handeleSaveEvents();
      if (paidValue > 0 && courseValue > 0) {
        await addPayments();
      }

      await onClose();
      setLoading(false);
    } else {
      if (events?.filter((event) => event.trainer_id === 0).length) {
        alert(t("alert.error.add_event.please_select_trainer"));
      } else if (events?.filter((event) => event.place_id === 0).length) {
        alert(t("alert.error.add_event.please_select_place"));
      } else if (events?.filter((event) => !event.date).length) {
        alert(t("alert.error.add_event.please_select_date"));
      } else if (events?.filter((event) => !event.time).length) {
        alert(t("alert.error.add_event.please_select_time"));
      }
    }
  };
  const handeleSaveEvents = async () => {
    if (events?.length) {
      try {
        await ZoozaApi.post(`/events`, {
          events: events,
        });

        getEvents?.();
        return true;
      } catch (e: any) {
        handleError(e.response.data, "alert");
        return false;
      }
    } else {
      alert(t("alert.error.add_event.please_select_events"));
    }
  };

  const defaultValue =
    ((Number(paid) + Number(toPay)) / Number(oldEvents?.length)) *
    Number(events?.length);

  useEffect(() => {
    const value = isNaN(defaultValue) ? FullCourseFee : defaultValue || 0;
    setPaidValue(fullRegType ? value : 0);
    setCourseValue(fullRegType ? value : 0);
  }, [events, oldEvents]);

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={t("modal.add_event.title.add_event")}
      submitButton={t("global.button.save")}
      cancelButton={t("global.button.cancel")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <>
        <form style={{ display: "grid", padding: 10 }} action="">
          <div className="payment-modal-item">
            <h5>{t("global.title.paid")}: </h5>
            <span> {convertToCurrency(paid, userData?.company.region)}</span>
            <h5>{t("global.amount_owed")}: </h5>
            <span> {convertToCurrency(toPay, userData?.company.region)}</span>
            <h5>{t("modal.add_event.registration_fee")}: </h5>{" "}
            <span>
              {" "}
              {convertToCurrency(FullCourseFee, userData?.company.region)}
            </span>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <h5>{t("modal.add_event.title.total_old_events")}: </h5>{" "}
              <span>{oldEvents?.length}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <h5>{t("modal.add_event.title.total_new_events")}: </h5>{" "}
              <span>{events?.length}</span>
            </div>
          </div>
          <fieldset className="radio_group payment-modal-item">
            <legend>{t("global.payment.title.method")}</legend>
            <ul>
              <li>
                <label htmlFor="credit">
                  <input
                    type="radio"
                    name="credit"
                    value={paymentType}
                    checked={paymentType === "credit"}
                    onChange={() => setPaymentType("credit")}
                    onClick={() => setPaymentType("credit")}
                    id="credit"
                  />
                  {t("modal.add_event.select.credit")}
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  name="CREDIT_VIA_TRANSFER"
                  value={paymentType}
                  checked={paymentType === "CREDIT_VIA_TRANSFER"}
                  onChange={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                  onClick={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                  id="CREDIT_VIA_TRANSFER"
                />
                <label htmlFor="CREDIT_VIA_TRANSFER">
                  {t("modal.add_event.select.transfer")}
                </label>
              </li>
            </ul>
          </fieldset>

          <br />

          <>
            <InputNumber
              setValue={setCourseValue}
              value={courseValue}
              label={t("modal.add_event.course_fee")}
            />
            <br />
            <div className="payment-modal-item">
              <div>
                <span>{t("modal.add_event.label.everything_paid")}</span>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="paid_whole"
                  checked={paidWhole}
                  onChange={() => setPaidWhole(!paidWhole)}
                  onClick={() => setPaidWhole(!paidWhole)}
                />
                {paidWhole && (
                  <span>
                    {" "}
                    {convertToCurrency(paidValue, userData?.company.region)}
                  </span>
                )}
              </div>
            </div>
            {!paidWhole && (
              <div className="payment-modal-item">
                <div className="payment-modal-item-label">
                  <h4>{t("modal.add_event.label.difference")}</h4>
                  <span>
                    {convertToCurrency(
                      Number(paidValue) - Number(courseValue),

                      userData?.company.region
                    )}
                  </span>
                </div>
                <div>
                  <span>{t("modal.add_event.label.paid")}</span>

                  <InputNumber
                    disabled={paidWhole}
                    label=""
                    value={paidValue}
                    setValue={setPaidValue}
                  />
                </div>
              </div>
            )}
          </>

          <div className="payment-modal-item">
            <span>{t("global.title.date")}</span>
            <DateTimePicker
              setDate={setPaymentDate}
              required={true}
              date={paymentDate.toDate()}
              help={""}
            />
          </div>

          <br />
          <div>
            <label htmlFor="note">
              <span>{t("global.title.note")}</span> <br />
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                id="note"
                name="note"
              />
            </label>
          </div>

          <br />
        </form>
      </>
    </Modal>
  );
};
