import React from "react";
import { Link, useLocation } from "react-router-dom";
import { msg } from "../services/common-functions";

export const BreadCrumbs = () => {
  const { pathname } = useLocation();
  const paths = pathname.split("/").filter((path) => path);
  const convertedPath = paths.map((element: string) => {
    return !isNaN(Number(element)) ? parseFloat(element) : element;
  });

  return (
    <nav className="z2 breadcrumbs">
      <ul>
        {convertedPath.map((path, index) => (
          <li key={index}>
            <Link to={"/" + convertedPath.slice(0, index + 1).join("/")}>
              <h2 style={paths.length - 1 === index ? { color: "black" } : {}}>
                {typeof path === "string" ? msg(path) : path}
              </h2>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
