import {t} from "i18next";
import React, {useEffect, useState} from "react";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow,
} from "../../components/zooza-card-components";
import {InvoiceAPIResponse, InvoiceProfiles,} from "../../types/payments-types";
import {ZoozaApi} from "../../services/Axios";
import {Registration} from "../../types/registration-types";
import {Modal} from "../../components/modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import {ZoozaSelect} from "../../components/inputs";
import {Course} from "../../types/courses-types";
import {useParams} from "react-router-dom";
import {LoadingButton} from "../../components/loading-button";
import {handleError} from "../../services/common-functions";
import {ErrorType} from "../../types/types";

type Options = {
    date: string;
    paymentType: string;
    note: string;
    paymentProfile?: number;
};
const lang = localStorage.getItem("i18nextLng") || "en";
export const InvoiceComponent = ({
                                     invoiceProfiles,
                                     registration,
                                     course,
                                 }: {
    invoiceProfiles: InvoiceProfiles[] | undefined;
    registration: Registration | undefined;
    course: Course | undefined;
}) => {
    const {id} = useParams();
    const [selectedProfile, setSelectedProfile] = useState<number | undefined>();
    const [open, setOpen] = useState(false);
    const [aftergenerating, setAftergenerating] = useState(false);
    const [invoices, setInvoices] = useState<InvoiceAPIResponse>();
    const [invoiceLink, setInvoiceLink] = useState<
        { id: number; link: string | null } | undefined
    >();
    const [invoiceLinkGenerating, setInvoiceLinkGenerating] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [options, setOptions] = useState<Options>({
        date: moment().format("YYYY-MM-DD"),
        paymentType: "ina",
        note: "",
    });
    const [error, setError] = useState<ErrorType>();
    useEffect(() => {
        setOptions({...options, note: course?.name || ""});
    }, [course]);
    const onProfileChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setOptions({...options, paymentProfile: Number(e.target.value)});
    };
    const getInvoices = async () => {
        const {data: invoice} = await ZoozaApi.get(
            `/customer_invoices/?order_id=${id}&order_type=course`
        );
        setInvoices(invoice);
    };
    useEffect(() => {
        getInvoices();
    }, []);
    const onGenerateInvoice = async () => {
        setInvoiceLoading(true);
        try {
            const {data} = await ZoozaApi.post("/customer_invoices", {
                order_id: registration?.id,
                order_type: "course",
                skip_queue: true,
                options: {
                    date: options.date,
                    text: options.note,
                    payment_method: options.paymentType,
                    invoice_profile: options.paymentProfile,
                    payment_profile: options.paymentProfile,
                },
            });
            if (data === true) {
                setOpen(false);
                setAftergenerating(true);
            } else if (typeof data === "number") {
                try {
                    const {data: invoice} = await ZoozaApi.get(
                        `/customer_invoices/${data}`
                    );
                } catch (e: any) {
                    console.log(e);
                }
            }
            setInvoiceLoading(false);
            getInvoices();
            setOpen(false);
        } catch (e: any) {
            handleError(e.response.data, "alert");
            console.log(e);
            setError(e as ErrorType);
        }
    };
    const onGenerateInvocieLink = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any
    ) => {
        setInvoiceLink({id: e.target.id, link: null});
        setInvoiceLinkGenerating(true);
        const id = e.target.id;

        try {
            const {data} = await ZoozaApi.get(`/customer_invoices/${id}/download`);
            setInvoiceLink({id: id, link: data.url});
        } catch (e) {
            console.error(e);
            setError(e as ErrorType);
        }
        setInvoiceLinkGenerating(false);
    };

    return (
        <>
            <ZoozaCard span={2}>
                <ZoozaCardHeader title={t("invoices.header.title")} icon="icon-bill"/>
                <div className="card_row_list_item mobile_single_column span_1">
                    <div className="list_item_label">
                        <p>{t("invoice.invoice_profile.title")}</p>
                    </div>

                    <div className="list_item_actions span_2">
                        <select
                            onChange={onProfileChange}
                            className="z2"
                            name="biling_profiles"
                            id="biling_profiles"
                        >
                            <option value="">
                                {t("invoice.invoice_profile.default_payment_profile")}
                            </option>
                            {invoiceProfiles?.map((profile) => (
                                <option key={profile.id} value={profile.id}>
                                    {profile.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {aftergenerating ? (
                    <div className="card_row_list_item notice success">
                        <div className="list_item_label span_3">
                            <p> {t("invoice.invoice_profile.message.invoice_created")}</p>
                        </div>
                    </div>
                ) : (
                    <div className="list_item_label span_3">
                        <p>
                            <small className="text_info">
                                {t("invoice.invoice_profile.message.help")}
                            </small>
                        </p>
                    </div>
                )}
                {invoices?.data.map((invoice) => (
                    <ZoozaCardRow key={invoice.id}>
                        <ZoozaCardCell>
                            <div className="list_item_label">
                                <p>
                                    {t("invoice.invoices.invoice_number")}: {invoice.invoice_no}
                                </p>
                                <p>
                                    {t("invoice.invoices.date")}: {invoice.created}
                                </p>
                                <p>
                                    {t("invoice.invoices.payment_method")}:{" "}
                                    {invoice.invoice_data?.f.f_payment}
                                </p>
                            </div>
                            <div className="list_item_actions">
                                <LoadingButton
                                    name={t("invoice.button.pre_download_invoice")}
                                    loading={
                                        invoiceLinkGenerating &&
                                        Number(invoiceLink?.id) === invoice.id
                                    }
                                    class="z2"
                                    id={String(invoice.id)}
                                    onClick={onGenerateInvocieLink}
                                />

                                {Number(invoiceLink?.id) === invoice.id &&
                                    invoiceLink?.link && (
                                        <a href={invoiceLink?.link} target="_blank">
                                            {t("invoice.button.download_invoice")}
                                        </a>
                                    )}
                                {Number(invoiceLink?.id) === invoice.id &&
                                    invoiceLink?.link === null && (
                                        <p>{t("invoice.invoices.invoice_not_ready")}</p>
                                    )}
                            </div>
                        </ZoozaCardCell>
                    </ZoozaCardRow>
                ))}

                <ZoozaCardActions>
                    <button onClick={() => setOpen(true)} className="z2 primary">
                        {t("global.button.create_invoice")}
                    </button>
                </ZoozaCardActions>
            </ZoozaCard>
            <InvoiceOptionsModal
                open={open}
                setOpen={setOpen}
                onSubmit={onGenerateInvoice}
                setOptions={setOptions}
                options={options}
                error={error}
                loading={invoiceLoading}
                billing_profile={options.paymentProfile}
            />
        </>
    );
};

export const InvoiceOptionsModal = ({
                                        open,
                                        setOpen,
                                        onSubmit,
                                        setOptions,
                                        options,
                                        error,
                                        loading,
                                        billing_profile
                                    }: {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: () => void;
    setOptions: (options: any) => void;
    options: Options;
    error?: ErrorType;
    loading?: boolean;
    billing_profile: number | undefined;
}) => {
    const onChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
        setOptions({...options, paymentType: e.target.value});
    };
    const paymentTypes = [
        {value: "ina", label: t("payment.type.other")},
        {value: "hotovost", label: t("payment.type.cash")},
        {value: "prevod", label: t("payment.type.bank")},
        {value: "eprovider", label: t("payment.type.online")},
        {value: "karta", label: t("payment.type.credit")},
    ];
console.log(billing_profile)
    return (
        <Modal
            submitButton={t("global.button.save")}
            cancelButton={t("global.button.cancel")}
            buttonDisabled={false}
            title={t("modal.invoice.header.invoice")}
            isOpen={open}
            onClose={() => setOpen(false)}
            onSubmit={onSubmit}
            loading={loading}
            errors={error}
        >
            {" "}
            <div style={{padding: 10}}>
                <fieldset>
                    <legend>{t("modal.invoice.date")}</legend>
                    <DatePicker
                        onChange={(date) => setOptions({...options, date: String(date)})}
                        selected={moment(options.date).toDate()}
                        dateFormat="yyyy-MM-dd"
                        locale={lang}
                    />
                </fieldset>
                <fieldset>
                    <legend>{t("global.payment.title.mehod")}</legend>
                    <ZoozaSelect param="" onChange={onChange} value={options.paymentType}>
                        {paymentTypes.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </ZoozaSelect>
                </fieldset>
                <fieldset>
                    <legend>{t("global.title.note")}</legend>
                    <textarea
                        onChange={(e) => setOptions({...options, note: e.target.value})}
                        name="note"
                        id="note"
                        value={options.note}
                    ></textarea>
                </fieldset>
            </div>
        </Modal>
    );
};
