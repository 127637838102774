import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import ICU from "i18next-icu";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import sk from "./locales/sk.json";
import cs from "./locales/cs.json";
import de from "./locales/de.json";
import ro from "./locales/ro.json";
import pl from "./locales/pl.json";
const initI18n = async () => {
  await i18n
      .use(ICU)
      .use(LanguageDetector)
      .use(intervalPlural)
      .use(initReactI18next)
      .init({
          debug: true,
          fallbackLng: "en",
          supportedLngs: ["en", "sk", "cs", "de", "ro", "pl"],
          returnEmptyString: false,
          lng: localStorage.getItem("i18nextLng") || "en",
          interpolation: {
              escapeValue: false,
          },
          resources: {
              sk: {
                  translation: sk,
              },
              en: {
                  translation: en,
              },
              cs: {
                  translation: cs,
              },
              de: {
                  translation: de,
              },
              ro: {
                  translation: ro,
              },
              pl: {
                  translation: pl,
              },
          },
      });
};

initI18n().then(r => console.log("i18n initialized"));

export default i18n;
