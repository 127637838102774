import { useContext, useEffect, useState } from "react";
import { ZoozaContextTypes } from "../types/types";
import { ZoozaContext } from "./zooza.context";

export const useAuthRole = ({ roles }: { roles: string[] }) => {
  const [isAllowedUser, setIsAllowed] = useState(false);
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);

  const isAllowed = () => {
    return roles.includes(String(userData?.user?.role));
  };

  useEffect(() => {
    setIsAllowed(isAllowed());
  }, [userData]);

  return isAllowedUser;
};
