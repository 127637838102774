import React, {ReactNode, StyleHTMLAttributes} from "react";
import {Link} from "react-router-dom";


type ZoozaRegistrationListProps = {
    children: ReactNode;
    style?: React.CSSProperties
};
export const ZoozaRegistrationList: React.FC<ZoozaRegistrationListProps> = ({
                                                                                children,
                                                                                style
                                                                            }) => {
    return (

        <div style={style} className={"registration_list"}>
            {children}
        </div>
    )
}
export const ZoozaRegistrationListRow = ({
                                             children
                                         }: {
    children: ReactNode;
}) => {
    return (
        <div className="registration_card_start">
            {children}
        </div>
    );
}

export const ZoozaRegistrationListRowEnd = ({
                                                children
                                            }: {
    children: ReactNode;
}) => {
    return (
        <div className="registration_card_end">
            {children}
        </div>
    );
}


export const ZoozaRegistrationListRowID = ({
                                               order_id,
                                               label_type,
                                               label_product
                                           }: {
    order_id: number | undefined
    label_type: string
    label_product: string
}) => {
    return (
        <div className="status">
            <h5>{label_type}</h5>
            <span>{label_product}</span>
            <div>
                <small style={{fontSize: 12}}>{order_id}</small>
            </div>
        </div>
    )
        ;
}

export const ZoozaRegistrationListRowName = ({
                                                 label_customer,
                                                 link_order_id,
                                                 customer_name,
                                                 customer_email,
                                                 customer_phone

                                             }: {
    label_customer: string
    link_order_id: string
    customer_name: string | undefined | null
    customer_email: string | undefined | null
    customer_phone: string | undefined | null
}) => {
    return (
        <div className="name">
            <h5>{label_customer}</h5>
            <Link to={link_order_id}>
                    <span>
                         {customer_name}
                    </span>

            </Link> <br/>
            <span className="small">{customer_email} <br/>{customer_phone}</span>

        </div>
    );
}
export const ZoozaRegistrationListRowPayment = ({label, paid}: { label: string; paid: number | undefined }) => {
    return (
        <div className="payment">
            <h5>{label}</h5>
            <span>{paid}</span>
        </div>
    )

}
export const ZoozaRegistrationListRowAttendance = ({label, product_name}: {
    label: string;
    product_name: string | undefined
}) => {
    return (
        <div className="attendance">
            <h5>{label}</h5>
            <span>{product_name}</span>
        </div>
    )

}

export const ZoozaRegistrationListNote = ({label, date}: { label: string; date: string | undefined }) => {
    return (
        <div className="note">
            <h5>{label}</h5>
            <span>{date}</span>
        </div>
    )

}









