import React, { useContext } from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { convertToCurrency } from "../../services/curency-service";
import { Payment } from "../../types/payments-types";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import { msg } from "../../services/common-functions";
import { useTranslation } from "react-i18next";
export const PaymentListComponent = ({
  payments,
}: {
  payments?: Payment[];
}) => {
  const { t } = useTranslation();
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const headers = [
    t("payment_list.header.amount"),
    t("payment_list.header.date"),
    t("payment_list.header.transaction_type"),
    t("payment_list.header.source"),
    t("payment_list.header.created_by"),
    t("payment_list.header.note"),
  ];
  console.log("payments", payments);
  return (
    <ZoozaCard>
      <ZoozaCardHeader icon="icon-coin-2" title={t("payment_list.title")} />

      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th>
                <span aria-label="Amount">{header}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {payments?.map((payment, index) => (
            <tr key={payment.id || index}>
              <td>
                <p>
                  <span
                    className={
                      Math.sign(payment.amount || 0) === -1
                        ? "num_negative"
                        : "num_positive"
                    }
                  >
                    {" "}
                    {convertToCurrency(
                      payment.amount,
                      userData?.company.region
                    )}
                  </span>
                </p>
              </td>
              <td>{payment.created}</td>
              <td>{msg(payment.transaction_type)}</td>
              <td>{msg(payment.origin)}</td>
              <td>{payment.created_by}</td>
              <td>{payment.note}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ZoozaCardActions>{""}</ZoozaCardActions>
    </ZoozaCard>
  );
};
