import React from "react";
import { useTranslation } from "react-i18next";
import { msg } from "../services/common-functions";
export const PaymentStatus = ({
  payment_status,
  icon,
}: {
  payment_status: string;
  icon?: boolean;
}) => {
  const { t } = useTranslation();
  let icon_class = "";
  let color_class = "color_neutral";
  if (payment_status === "paid") {
    icon_class = "icon-happy-emoji icon_large";
    color_class = "color_success";
  } else if (
    payment_status === "partially_paid" ||
    payment_status === "overpaid"
  ) {
    icon_class = "icon-confused-emoji icon_large";
    color_class = "color_warning";
  } else if (payment_status === "unpaid") {
    icon_class = "icon-sad-emoji icon_large";
    color_class = "color_error";
  } else {
    icon_class = "";
    color_class = "color_neutral";
  }

  return (
    <div className={color_class}>
      {icon && <PaymentIcon payment_status={payment_status} />}
      &nbsp;
      <span>{msg(payment_status || "")}</span>
    </div>
  );
};

export const PaymentIcon = ({
  payment_status,
}: {
  payment_status?: string;
}) => {
  let icon_class = "";
  if (payment_status === "paid") {
    icon_class = "icon-happy-emoji icon_large color_success";
  } else if (
    payment_status === "partially_paid" ||
    payment_status === "overpaid"
  ) {
    icon_class = "icon-confused-emoji icon_large color_warning";
  } else if (payment_status === "unpaid") {
    icon_class = "icon-sad-emoji icon_large color_error";
  } else {
    icon_class = "";
  }

  return <em className={icon_class}></em>;
};
