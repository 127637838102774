import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bake_cookie, read_cookie } from "../services/Axios";

export const VerifyPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  bake_cookie("token", token || "");
  bake_cookie("token-legacy", token || "");
  const cookieToken = read_cookie("token");
  useEffect(() => {
    navigate("/");
    window.location.reload();
  }, [cookieToken]);
  return <h2 style={{ margin: 10 }}>/verify</h2>;
};
