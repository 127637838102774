import {useParams} from "react-router-dom";

import {ClientComponent} from "../registartion-page/client-component";

import {PaymentCompoment} from "../registartion-page/payment-component";

import {AddPaymentModal} from "../../components/add-payment-modal";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ZoozaApi} from "../../services/Axios";
import {Items, Order} from "../../types/orders-types";
import {OrderComponent} from "./order-component";
import {Registration} from "../../types/registration-types";
import {ZoozaCard, ZoozaCardActions, ZoozaCardHeader,} from "../../components/zooza-card-components";
import {msg} from "../../services/common-functions";
import "../../styles/_registration_list.scss"

export const OrderDetailPage = () => {
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();
    const [order, setOrder] = useState<Order>();
    const [registrations, setRegistrations] = useState<Registration[]>([]);
    const [items, setItems] = useState<Items[]>([]);
    const {id} = useParams();
    const getOrder = async () => {
        const {data: order} = await ZoozaApi.get(`/orders/${id}`);
        setOrder(order);
    };
    const getRegistrations = async () => {
        try {
            const {data: registrationsData} = await ZoozaApi.get(
                `/registrations?advanced_search=1&order_id=${id}&1=1`
            );
            const registrations = registrationsData.results;
            setRegistrations(registrations);
        } catch (e) {
            console.log(e);
        }
    };
    const getItems = async () => {
        try {
            const {data: items} = await ZoozaApi.get(`/orders/${id}/items`);
            setItems(items.data);
        } catch (e) {
        }
    };
    useEffect(() => {
        getOrder();
        getRegistrations();
        getItems();
    }, []);

    return (
        <div className="z2 app_page_layout">
            <div className="z2 app_page_toolbar"></div>

            <div className="app_page_body">
                <div className="app_page_column span_2">
                    <PaymentCompoment order={order} setOpen={setOpen}/>
                </div>
                <div className="app_page_column span_2">
                    <OrderComponent order={order} registrations={registrations}/>
                    <ClientComponent registration={order}/>
                </div>
                <div className="app_page_column span_4">
                    <ProductItemsComponent items={items}/>
                </div>
            </div>

            <AddPaymentModal
                open={open}
                setOpen={setOpen}
                order_id={order?.id}
                type="payment"
                order_type={"product"}

            />
        </div>
    );
};

const ProductItemsComponent = ({items}: { items: Items[] }) => {
    const {t} = useTranslation();

    return (
        <ZoozaCard>
            <ZoozaCardHeader
                icon="icon-mail"
                title={t("orders.title.product_items")}
            />
            <table>
                <thead>
                <tr>
                    <th>
              <span aria-label={t("orders.header.product_name")}>
                {t("orders.header.product_name")}{" "}
              </span>{" "}
                    </th>
                    <th>
              <span aria-label={t("orders.header.product_type")}>
                {t("orders.header.product_type")}{" "}
              </span>{" "}
                    </th>
                    <th>
              <span aria-label={t("orders.header.credit_name")}>
                {t("orders.header.credit_name")}{" "}
              </span>{" "}
                    </th>
                    <th>
              <span aria-label={t("orders.header.info")}>
                {t("orders.header.info")}{" "}
              </span>{" "}
                    </th>
                    <th>
              <span aria-label={t("orders.header.price")}>
                {t("orders.header.price")}{" "}
              </span>{" "}
                    </th>
                </tr>
                </thead>
                <tbody>
                {items?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.product.name}</td>
                            <td>{msg(item.item_type)}</td>
                            <td>{item.credit.code}</td>
                            <td>{item.product.intro_text}</td>
                            <td>{item.product.price}</td>

                        </tr>
                    );
                })}
                </tbody>
            </table>
            <ZoozaCardActions>{""}</ZoozaCardActions>
        </ZoozaCard>
    );
};
