import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { Agreements } from "../../components/agreements";
import { ProgressButtons } from "./progress-buttons";
import { ExtraFields } from "./render-extra-fields";
import { NewRegistration } from "../../types/registration-types";
import { Course, ExtraField } from "../../types/courses-types";
import {
  CreateRegistrationContextTypes,
  ZoozaContextTypes,
} from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import {
  ZoozaTable,
  ZoozaTableActions,
  ZoozaTableBody,
  ZoozaTableCell,
  ZoozaTableHeader,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../../components/zooza-table-components";
import { CreateRegistrationContext } from "./create-registration-context";

type RegistrationCard = {
  progress: number;
  setProgress: (value: number) => void;
};
export const RegistrationCard = ({
  progress,

  setProgress,
}: RegistrationCard) => {
  const [disabled, setDisabled] = React.useState(false);
  const [clickCounts, setClickCounts] = useState<number[]>([]);
  const [agreemntsOk, setAgreemntsOk] = React.useState(false);
  const [registrationsOk, setRegistrationsOk] =
    React.useState<registrationValidation[]>();
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { setRegistrations, registrations, agreements, course } =
    useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);

  const initialExtraFieldsValues = course?.extra_fields
    ?.filter((field) => field.active === true)
    ?.reduce(
      (acc, field) => {
        if (field.column_name === "slots") {
          acc[field.column_name] = "1";
        } else {
          acc[field.column_name] = "";
        }
        console.log("hekk", registrations);
        return acc;
      },
      { first_name: "", last_name: "", phone: "", email: "", slots: "1" } as {
        [key in
          | ExtraField["column_name"]
          | "first_name"
          | "last_name"
          | "phone"
          | "email"
          | "slots"]: string;
      }
    );
  const registration = registrations?.[0]; // Use optional chaining operator to prevent accessing the array if it's undefined
  const initialExtraFieldsValues2 = null;

  const addRegistration = async () => {
    try {
      const courseName = "Jason Statham swimming training "; // Change this to the desired course name
      if (course?.get_basic_fields_from === "registrant") {
        setRegistrations?.([
          ...(registrations as unknown as NewRegistration[]),
          initialExtraFieldsValues2 as unknown as NewRegistration,
        ]);
        const newClickCounts = [...clickCounts, clickCounts.length + 1];
        setClickCounts(newClickCounts);
      } else {
        setRegistrations?.([
          ...(registrations as unknown as NewRegistration[]),
          initialExtraFieldsValues as unknown as NewRegistration,
        ]);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  const disabledFunc = () => {
    if (
      registrationsOk?.filter((r) => r.valid === true)?.length ===
        registrations?.length &&
      agreemntsOk &&
      Number(course?.registration_slots_min) <= Number(registrations?.length)
    ) {
      setDisabled(false);
    } else if (
      course?.get_basic_fields_from === "registrant" &&
      Number(course?.registration_slots_max) >= Number(registrations?.length) &&
      Number(course?.registration_slots_min) <= Number(registrations?.length) &&
      registration?.first_name !== "" &&
      registration?.last_name !== "" &&
      registration?.email !== "" &&
      registration?.phone !== "" &&
      agreemntsOk
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    disabledFunc();
  }, [registrations, agreements, agreemntsOk, registrationsOk]);
  useEffect(() => {
    const checkBoxAgreements = userData?.agreements.filter(
      (agreement) => agreement.type === "checkbox"
    );
    const radioAgreements = userData?.agreements.filter(
      (agreement) => agreement.type === "radio"
    );
    const checkBoxAgreementsManadatory = checkBoxAgreements?.filter(
      (agreement) => agreement.mandatory === 1
    );
    const radioAgreementsManadatory = radioAgreements?.filter(
      (agreement) => agreement.mandatory === 1
    );
    const checkBoxCheckedMandatoryAgreements = agreements?.filter(
      (agreement) => agreement.mandatory === 1 && agreement.type === "checkbox"
    );

    const radioCheckedMandatoryAgreements = agreements?.filter(
      (agreement) =>
        agreement.mandatory === 1 &&
        agreement.type === "radio" &&
        agreement.gave_consent === true
    );
    const checkBoxCheckedMandatoryAgreementOk =
      checkBoxCheckedMandatoryAgreements?.length ===
        checkBoxAgreementsManadatory?.length ||
      checkBoxAgreementsManadatory?.length === 0;
    const radioCheckedMandatoryAgreementOk =
      radioCheckedMandatoryAgreements?.length ===
        radioAgreementsManadatory?.length ||
      radioAgreementsManadatory?.length === 0;

    setAgreemntsOk(
      checkBoxCheckedMandatoryAgreementOk && radioCheckedMandatoryAgreementOk
    );
  }, [agreements]);
  const validationRegistrationHandler = (id: number, value: boolean) => {
    setRegistrationsOk(
      (prevRegistrations: registrationValidation[] | undefined) => {
        if (!prevRegistrations) return [];

        const index = prevRegistrations.findIndex((item) => item.id === id);

        if (index === -1) {
          return [...prevRegistrations, { id, valid: value }];
        }

        return prevRegistrations.map((item, i) =>
          i === index ? { ...item, valid: value } : item
        );
      }
    );
  };

  return (
    <ZoozaTable disabled={progress !== 3}>
      <ZoozaTableHeader>
        <h3>{t("registrations.create.title.registrations")}</h3>
      </ZoozaTableHeader>

      <ZoozaTableBody>
        {progress === 3 && (
          <>
            <ZoozaTableRow>

              {registrations?.map((registration, index) => {
                if (registration === null) {
                  const removeThisRegistration = () => {
                    setRegistrations?.(
                      registrations?.filter(
                        (registration, i) => i !== index
                      ) as never[]
                    );

                  };
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "white",
                        padding: "10px",
                        marginBottom: "1px",
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                      }}
                    >
                      <h3
                        style={{
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("registrations.create.title.added_registration", {
                          index: index + 1,
                        })}
                      </h3>
                      <div
                        style={{ display: "flex", justifyContent: "end" }}
                        key={index}
                      >
                        <a
                          onClick={removeThisRegistration}
                          role="button"
                          className="rounded right"
                        >
                          <span className="icon-cross"></span>
                        </a>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <RegistrationInputs
                      key={index}
                      course={course}
                      registration={registration}
                      index={index}
                      validationRegistrationHandler={
                        validationRegistrationHandler
                      }
                    />
                  );
                }
              })}
            </ZoozaTableRow>
            <ZoozaTableRow>
              <ZoozaTableRowItem>
                <ZoozaTableCell span="12">
                  <Agreements checkedAgreements={agreements} />
                </ZoozaTableCell>
              </ZoozaTableRowItem>
            </ZoozaTableRow>
          </>
        )}
      </ZoozaTableBody>
      {progress === 3 && (
        <ZoozaTableActions>
          {Number(course?.registration_slots_max) >
            Number(registrations?.length) && (
            <button onClick={addRegistration} className="z2 primary">
              {t("registrations.create.button.add_registration")}
            </button>
          )}
          <ProgressButtons
            disabled={disabled}
            setProgress={setProgress}
            next={4}
            back={2}
          />
        </ZoozaTableActions>
      )}
    </ZoozaTable>
  );
};

type registrationValidation = {
  id: number;
  valid: boolean;
};

const RegistrationInputs = ({
  course,
  registration,
  index,
  validationRegistrationHandler,
}: {
  course: Course | undefined;
  registration: NewRegistration;
  index: number;
  validationRegistrationHandler: (id: number, value: boolean) => void;
}) => {
  const { setRegistrations, registrations } =
    useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
  useEffect(() => {
    const first_name = registration?.first_name;
    const last_name = registration?.last_name;
    const email = registration?.email;
    const phone = registration?.phone;
    if (
      first_name === "" ||
      first_name === undefined ||
      last_name === "" ||
      last_name === undefined ||
      email === "" ||
      email === undefined ||
      phone === "" ||
      phone === undefined
    ) {
      validationRegistrationHandler(index, false);
    } else {
      validationRegistrationHandler(index, true);
    }
  }, [registration]);
  const updateRegistrationAtIndex = (
    registrations: any[],
    index: number,
    updatedValues: object
  ) => [
    ...registrations.slice(0, index),
    { ...registrations[index], ...updatedValues },
    ...registrations.slice(index + 1),
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrations?.((prevRegistrations: NewRegistration[] | undefined) => {
      const updatedRegistrations = updateRegistrationAtIndex(
        prevRegistrations || [],
        index,
        { [e.target.id]: e.target.value }
      ) as NewRegistration[];
      return updatedRegistrations;
    });
  };

  const removeThisRegistration = () => {
    setRegistrations?.(
      registrations?.filter((registration, i) => i !== index) as never[]
    );
  };

  return (
    <>
      {index !== 0 && (
        <div style={{ display: "flex", justifyContent: "end" }} key={index}>
          <a
            onClick={removeThisRegistration}
            role="button"
            className="rounded right"
          >
            <span className="icon-cross"></span>
          </a>
        </div>
      )}
      <ZoozaTableRowItem>
        <ZoozaTableCell span="6">
          {" "}
          <span>{t("global.title.first_name")}</span>
          <input
            type={"text"}
            id={"first_name"}
            // placeholder={t("first_name__placeholder")}
            value={registration?.first_name}
            onChange={onChange}
            required
          />
        </ZoozaTableCell>
        <ZoozaTableCell span="6">
          <span>{t("global.title.last_name")}</span>

          <input
            type={"text"}
            id={"last_name"}
            // placeholder={t("last_name_placeholder")}
            value={registration?.last_name}
            onChange={onChange}
            required
          />
        </ZoozaTableCell>
      </ZoozaTableRowItem>
      <ZoozaTableRowItem>
        <ZoozaTableCell span="6">
          <span>{t("global.title.email")}</span>

          <input
            type={"email"}
            id={"email"}
            // placeholder={t("email__placeholder")}
            value={registration?.email}
            onChange={onChange}
            required
          />
        </ZoozaTableCell>
        <ZoozaTableCell span="6">
          <span>{t("global.title.phone")}</span>

          <input
            type={"text"}
            id={"phone"}
            // placeholder={t("phone__placeholder")}
            value={registration?.phone}
            onChange={onChange}
          />
        </ZoozaTableCell>
      </ZoozaTableRowItem>
      {course?.use_extra_fields && (
        <ExtraFields
          registration={registration}
          course={course}
          index={index}
        />
      )}
    </>
  );
};
