import moment, { Moment } from "moment";
import React from "react";
import {
  ZoozaDateOfBirth,
  ZoozaFullNameInput,
  ZoozaInput,
} from "../../components/inputs";
import { Course, ExtraField } from "../../types/courses-types";
import { NewRegistration } from "../../types/registration-types";
import {
  ZoozaCardCell,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import {
  ZoozaTable,
  ZoozaTableCell,
  ZoozaTableRowItem,
} from "../../components/zooza-table-components";
import { CreateRegistrationContext } from "./create-registration-context";
import { CreateRegistrationContextTypes } from "../../types/types";
import { msg } from "../../services/common-functions";

const RenderExtraFieldInput = ({
  field,
  registration,
  index,
}: {
  field: ExtraField;
  registration: NewRegistration;
  index: number;
}) => {
  const { setRegistrations, registrations } =
    React.useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
  const updateRegistrationAtIndex = (
    registrations: any[],
    index: number,
    updatedValues: object
  ) => [
    ...registrations.slice(0, index),
    { ...registrations[index], ...updatedValues },
    ...registrations.slice(index + 1),
  ];

  const onDateChange = (date: Moment) => {
    setRegistrations?.((prevRegistrations: NewRegistration[] | undefined) => {
      const updatedRegistrations = updateRegistrationAtIndex(
        prevRegistrations || [],
        index,
        { date_of_birth: moment(date).format("YYYY-MM-DD") }
      ) as NewRegistration[];
      return updatedRegistrations;
    });
  };
  switch (field.column_name) {
    case "dob":
      return (
        <ZoozaDateOfBirth
          key={field.column_name}
          param={field.column_name}
          value={registration?.date_of_birth}
          placeholder={field.name || msg(field.column_name)}
          onDateChange={onDateChange}
        />
      );
    case "full_name":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "address":
      return (
        <InputText field={field} registration={registration} index={index} />
      );

    case "business_address":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "business_id":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "tax_id":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "vat":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "business_name":
      return (
        <InputText field={field} registration={registration} index={index} />
      );

    case "identification_number":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "slots":
      return (
        <InputText
          field={field}
          registration={registration}
          index={index}
          value={"1"}
        />
      );
    case "extra_field_1":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "extra_field_2":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "extra_field_3":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "extra_field_4":
      return (
        <InputText field={field} registration={registration} index={index} />
      );
    case "extra_field_5":
      return (
        <InputText field={field} registration={registration} index={index} />
      );

    default:
      return null;
  }
};
export const ExtraFields = ({
  course,
  registration,
  index,
}: {
  course?: Course;
  registration: NewRegistration;
  index: number;
}) => {
  const activeExtraFields =
    course?.extra_fields?.filter((field) => field.active === true) || [];

  const rows = [];

  for (let i = 0; i < activeExtraFields.length; i += 1) {
    const field = activeExtraFields[i];

    rows.push(
      <div key={`row-${i}`}>
        <ZoozaTableRowItem>
          <ZoozaTableCell span="12">
            <span>{field.name || msg(field.column_name)}</span>

            <RenderExtraFieldInput
              field={field}
              index={index}
              registration={registration}
            />
          </ZoozaTableCell>
        </ZoozaTableRowItem>
      </div>
    );
  }

  return <>{rows}</>;
};

const InputText = ({
  field,
  registration,
  index,
  value = "",
}: {
  field: ExtraField;
  registration: NewRegistration | any;
  index: number;

  value?: string;
}) => {
  const { setRegistrations, registrations } =
    React.useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
  const updateRegistrationAtIndex = (
    registrations: any[],
    index: number,
    updatedValues: object
  ) => [
    ...registrations.slice(0, index),
    { ...registrations[index], ...updatedValues },
    ...registrations.slice(index + 1),
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrations?.((prevRegistrations: NewRegistration[] | undefined) => {
      const updatedRegistrations = updateRegistrationAtIndex(
        prevRegistrations || [],
        index,
        { [field.column_name]: e.target.value }
      ) as NewRegistration[];
      return updatedRegistrations;
    });
  };
  return (
    <input
      key={field.column_name}
      type={"text"}
      id={field.column_name}
      value={registration ? registration[field.column_name] : "" || value}
      defaultValue={value}
      // placeholder={field.name || msg(field.column_name)}
      onChange={onChange}
    />
  );
};
