import React, { useContext, useEffect, useState } from "react";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import {
  ZoozaTable,
  ZoozaTableBody,
  ZoozaTableCell,
  ZoozaTableHeader,
  ZoozaTablePagination,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../../components/zooza-table-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import i18n from "../../locale";
import { ZoozaApi } from "../../services/Axios";
import { Event } from "../../types/registration-types";
export const MyUpcomingEvents = () => {
  const lang = i18n.language;
  const { courses, userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [recentEvents, setRecentEvents] = useState<Event[] | undefined>();
  useEffect(() => {
    if (userData !== null) {
      getRecentEvents();
    }
  }, [userData]);

  const getRecentEvents = async () => {
    try {
      const { data } = await ZoozaApi.get(`/events?upcoming_events=true&1=1`);
      setRecentEvents(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  const { t } = useTranslation();
  return (
    <ZoozaTable>
      <ZoozaTableHeader>
        <div className="results_found">
          <h3>
            {" "}
            <em className="icon-calendar"></em>{" "}
            {t("dashboard.title.my_upcoming_events")}
          </h3>
        </div>
      </ZoozaTableHeader>
      <ZoozaTableBody>
        {recentEvents &&
          recentEvents?.length > 0 &&
          recentEvents?.map((event) => {
            const course = courses?.find(
              (course) => course.id === event.course_id
            );
            return (
              <ZoozaTableRow key={event.id}>
                <ZoozaTableRowItem>
                  <ZoozaTableCell span="5">
                    <span aria-label={t("global.title.date")}>
                      {t("global.title.date")}
                    </span>
                    {moment(event.date).isValid()
                      ? moment(event?.date).locale(lang).format("LLLL")
                      : ""}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="5">
                    <span aria-label={t("global.title.course")}>
                      {t("global.title.course")}
                    </span>
                    {course?.name}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="2">
                    {" "}
                    <Link to={"calendar/" + event.id}>
                      {t("dashboard.my_upcoming_events.view")}
                    </Link>
                  </ZoozaTableCell>
                </ZoozaTableRowItem>
              </ZoozaTableRow>
            );
          })}
      </ZoozaTableBody>
      <ZoozaTablePagination></ZoozaTablePagination>
    </ZoozaTable>
  );
};
