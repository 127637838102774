import React, { useContext } from "react";
import "../styles/loading.css";
import { ZoozaContextTypes } from "../types/types";
import { ZoozaContext } from "../services/zooza.context";
export const Loading = () => {
  const { loading } = useContext<ZoozaContextTypes>(ZoozaContext);

  return (
    <div>
      {loading && (
        <div className="loader">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      )}
    </div>
  );
};
