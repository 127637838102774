import React, {useEffect, useState} from "react";
import {ZoozaApi} from "../../services/Axios";
import useDebounce from "../../hooks/debounce-search";
import {useTranslation} from "react-i18next";
import {Order, OrdersResult} from "../../types/orders-types";
import "../../styles/_registration_list.scss"
import {OrderRow} from "./order-row";
import {Aside} from "./aside";
import {ZoozaTablePagination} from "../../components/zooza-table-components";


export const OrdersPage = () => {
    const {t} = useTranslation();
    const [ordersData, setOrdersData] = useState<OrdersResult>();
    const [orders, setOrders] = useState<Order[]>();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const debouncedSearchTerm = useDebounce(search, 500);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>("any");
    const getOrders = async () => {

        try {
            setLoading(true)
            const {data: orders} = await ZoozaApi.get(
                `/orders?advanced_search=1&product_name=${debouncedSearchTerm}&payment_status=${paymentStatus}&page=${
                    page - 1
                }&status=null&created_from=null&created_to=null`
            );
            setOrdersData(orders);
            setOrders(orders.data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching orders:", error);
            setLoading(false)
        }
    };
    useEffect(() => {
        getOrders();
    }, [debouncedSearchTerm, page, paymentStatus]);
    return (<>
            <div className="z2 app_page_toolbar" data-bind="sticky: 'ahoj'">
            </div>
            <div className="app_page_body">
                <div style={{gridColumn: "span 4"}}>
                    <Aside search={search} setSearch={setSearch} paymentStatus={paymentStatus}
                           setPaymentStatus={setPaymentStatus}
                    loading={loading}/>
                    <div className={"registration_list"}>
                        {orders?.map((order) => (
                            <OrderRow key={order.id} order={order}/>
                        ))}

                    </div>
                    <div className="pagination">
                        <ZoozaTablePagination page={page} total={ordersData?.total} setPage={setPage} perPage={ordersData?.page_size} />
                    </div>
                </div>
            </div>
        </>
    );
};




type PaymentStatus = "any" | "paid" | "unpaid";
