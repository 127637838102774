import React, { useState } from "react";
import Logo from "../assets/img/logo_zooza.svg";
import { LanguageSelector } from "../components/language-selector"; // Import your LanguageSelector component
import { useTranslation } from "react-i18next";
import { ZoozaApi, bake_cookie } from "../services/Axios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../components/loading-button";
import "../styles/login.css";
import { handleError } from "../services/common-functions";

export const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [pinPage, setPinPage] = useState(false);
  const [smalLloading, setSmallLoading] = useState(false);

  const [validationError, setValidationError] = useState("");

  const handleRequestLoginCode = async () => {
    setSmallLoading(true);
    try {
      const { data } = await ZoozaApi.post("/login", { login: email });
      if (data.login_email_sent) {
        setPinPage(true);
      } else {
        setValidationError(t("error.login.invalid_email"));
      }
    } catch (error: any) {
      const message = handleError(error.response.data, "message");
      setValidationError(message || t("error.login.invalid_email"));
    }
    setSmallLoading(false);
  };

  const handleValidatePin = async () => {
    setSmallLoading(true);
    try {
      const { data } = await ZoozaApi.post("/verify", {
        pin: Number(pin),
        action: "validate_pin",
        email: email,
      });

      if (data.token) {
        bake_cookie("token", data.token);
        bake_cookie("token-legacy", data.token);
        window.location.href = "/";
      }
    } catch (err: any) {
      handleError(err.response.data, "alert");
      setValidationError(t("error.login.invalid_pin"));
    }
    setSmallLoading(false);
  };

  const { t } = useTranslation();

  return (
    <div className="login">
      <div></div>
      <div style={{ display: "grid" }}>
        <div className="branding">
          <img src={Logo} alt="logo" />
        </div>
        <section className="z2 card">
          <div className="card_header">
            <em className="icon-key icon_large"></em>
            <div>
              <h3>{t("login.title")}</h3>
            </div>
          </div>

          <div
            className="card_row_list_item"
            style={{ display: !pinPage ? "" : "none" }}
          >
            <div className="list_item_value span_3">
              <label htmlFor="email">
                <span>{t("login.enter_email")}</span>
                <strong>
                  <abbr title="required">*</abbr>
                </strong>
                <input
                  type={"email"}
                  id={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <p style={{ color: "red" }}>{validationError}</p>
            </div>
          </div>

          <div
            className="card_row_list_item"
            style={{ display: pinPage ? "" : "none" }}
          >
            <div className="list_item_value span_3">
              <label htmlFor="pin">
                <span>{t("login.check_your_email")}</span>
                <strong>
                  <abbr title="required">*</abbr>
                </strong>
                <input
                  type={"text"}
                  id={"pin"}
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                  required
                />
              </label>
            </div>
          </div>

          <div className="card_actions">
            <LoadingButton
              style={{ display: !pinPage ? "block" : "none" }}
              loading={smalLloading}
              name={t("login.button.login")}
              class="z2"
              onClick={handleRequestLoginCode}
            />
            <LoadingButton
              style={{ display: pinPage ? "block" : "none" }}
              loading={smalLloading}
              name={t("login.button.verify_pin")}
              class="z2"
              onClick={handleValidatePin}
            />

            <button
              className="z2"
              onClick={() => setPinPage(false)}
              style={{ display: pinPage ? "block" : "none" }}
            >
              {t("general.button.back")}
            </button>
          </div>
        </section>

        <div className="company">
          <img src={Logo} alt="company logo" />
          <style>
            {`.language_selector {
          justify-content: center;
          font-size: 14px;
        }`}
          </style>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};
