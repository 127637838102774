import React, { useEffect, useState } from "react";
import moment from "moment";
import { CalendarEvent, Member, Place, Room } from "../types/types";
import "../styles/calendar.css";
import { DailyAttendance } from "../types/schedule-type";
import { CalendarEventItem } from "./event-item";
import { useNavigate } from "react-router-dom";
import { ZoozaApi } from "../services/Axios";
import { LoadingButton } from "./loading-button";
import { handleError } from "../services/common-functions";

export const DailyOverview = ({
  place,
  members,
  events,
  date,
  getTrainersPlaces,
  attendances,
}: {
  place: Place | undefined;
  members: Member[];
  events: CalendarEvent[] | undefined;
  handleDate: (num: number) => void;
  date: string;
  getTrainersPlaces: () => void;
  attendances: DailyAttendance[] | undefined;
}) => {
  const [loading, setLoading] = useState<number | null>(null);
  const navigate = useNavigate();
  const times = Array.from(Array(24).keys()).map((hour) => {
    const time = moment().hour(hour).minute(0).format("HH:mm");
    return {
      id: hour,
      time: time,
    };
  });
  const filteredEvents = place
    ? events?.filter((event) => event.place_id === place.id)
    : events;
  const onClickCreateEvent = (e: any) => {
    const [time, member_id, place_id] = e.target.id.split("-");

    navigate(
      `/registrations/create/?time=${time}&member=${member_id}&place=${place_id}&date=${moment(
        date
      ).format("YYYY-MM-DD")}`
    );
  };

  const handleDeleteLimit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(Number((e.target as HTMLButtonElement).id));
    try {
      const { data } = await ZoozaApi.delete(
        `/trainer_places/${(e.target as HTMLButtonElement).id}`
      );

      if (data) {
        getTrainersPlaces();
      }
    } catch (err: any) {
      handleError(err.response.data, "alert");
      setLoading(null);
    }
  };
  const placeMembers = members?.filter(
    (member) =>
      member.limit?.place_id === place?.id &&
      (member.limit?.room_id === null ||
        member.limit?.room_id === undefined ||
        member.limit?.room_id === 0)
  );
  const roomsMebers = members?.filter(
    (member) =>
      member.limit?.place_id === place?.id &&
      member.limit?.room_id !== null &&
      member.limit?.room_id !== undefined &&
      member.limit?.room_id !== 0
  );

  return (
    <div className="calendar">
      <div className="calendar-header"></div>
      <div className="calendar-body">
        <ul>
          {filteredEvents?.map((event) => (
            <CalendarEventItem
              attendances={attendances}
              key={event?.id}
              event={event}
              place={place}
              members={members}
            />
          ))}
        </ul>
        <div className="calendar-lectors">
          <ul>
            <div style={{ height: 50 }}></div>
            <li className="place-separator">{place?.name}</li>
            {placeMembers?.map((member) => (
              <li key={member.id} style={{ position: "relative" }}>
                <p>
                  {member.first_name} {member.last_name}
                </p>
                <LoadingButton
                  onClick={handleDeleteLimit}
                  class="delete-place-member"
                  id={String(member?.limit?.id)}
                  name="x"
                  loading={loading === member?.limit?.id}
                />
              </li>
            ))}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator">{room.name}</li>
                <ul>
                  {roomsMebers
                    ?.filter(
                      (member) =>
                        Number(member?.limit?.room_id) === Number(room.id)
                    )
                    .map((member) => (
                      <li key={member.id} style={{ position: "relative" }}>
                        <p>
                          {member.first_name} {member.last_name}
                        </p>
                        <LoadingButton
                          onClick={handleDeleteLimit}
                          class="delete-place-member"
                          id={String(member?.limit?.id)}
                          name="x"
                          loading={loading === member?.limit?.id}
                        />
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            ))}
          </ul>
        </div>
        {times?.map((time) => (
          <div className="time-slot">
            <div className="time-cell" key={time.time}>
              {time.time}
            </div>
            {place ? (
              <li className="place-separator"></li>
            ) : (
              <li className="place-separator"></li>
            )}
            {placeMembers?.map((member) => (
              <li
                onClick={onClickCreateEvent}
                id={`${time.time}-${member.id}-${place?.pid}`}
                key={member.id}
              ></li>
            ))}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator"></li>
                <ul>
                  {roomsMebers
                    ?.filter(
                      (member) =>
                        Number(member?.limit?.room_id) === Number(room.id)
                    )
                    ?.map((member) => (
                      <li
                        onClick={onClickCreateEvent}
                        id={`${time.time}-${member.id}-${room.pid}`}
                        key={member.id}
                      ></li>
                    ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
