import moment, { Moment } from "moment";
import React, { Children, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type ZoozaInputProps = {
  param: string;
  value?: string | number | undefined | readonly string[];
  onChange?: (
    e: React.ChangeEvent | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (date: Moment) => void;
  type?: string;
  placeholder?: string;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  disabled?: boolean;
  label?: string;
};
export const ZoozaSelect = ({
  param,
  value,
  children,
  onChange,
  disabled,
  label,
  style,
}: ZoozaInputProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filtering = (value: string, key: string) => {
    if (key !== "") {
      // Update the search parameters using the setSearchParams function
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        newSearchParams.set(key, value);
        return newSearchParams;
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        justifyContent: "left",

        ...style,
      }}
    >
      {label && (
        <label
          style={{ fontSize: 12, marginLeft: 5, textAlign: "left" }}
          htmlFor={param || "select"}
        >
          {label}
        </label>
      )}
      <select
        aria-label={label}
        disabled={disabled}
        onChange={(e) => {
          filtering(e.target.value, param);
          onChange?.(e);
        }}
        style={{ width: 200, marginLeft: 10,  height: 35 }}
        className="z2"
        value={
          searchParams.get(param)
            ? String(searchParams.get(param))
            : String(value) || ""
        }
        id={param || "select"}
      >
        {children}
      </select>
    </div>
  );
};

export const ZoozaDateOfBirth = ({
  onDateChange,
  id,
  param,
  placeholder,
}: ZoozaInputProps) => {
  const [date, setDate] = useState({
    day: moment().day(),
    month: moment().month(),
    year: moment().year(),
  });
  const years = Array.from(Array(100).keys()).map(
    (year) => moment().year() - year
  );
  const months = Array.from(Array(12).keys()).map((month) => month + 1);
  const days = moment([`${date.year}-${date.month}`], "YYYY-MM").daysInMonth();

  useEffect(() => {
    if (date.month === 2) {
      if (date.day > 28) {
        setDate({ ...date, day: days });
      }
    } else if (date.day > 30) {
      setDate({ ...date, day: 30 });
    }
  }, [date.month, date.year]);

  useEffect(() => {
    onDateChange?.(moment([date.year, date.month - 1, date.day]));
  }, [date]);

  return (
    <div className="z2">
      <select
        value={date.day}
        onChange={(e) =>
          setDate({ ...date, day: Number(e.target.value) as unknown as number })
        }
        id={id}
        // placeholder={placeholder}
      >
        {Array.from(Array(days || 31).keys()).map((day) => (
          <option value={day + 1}>{day + 1}</option>
        ))}
      </select>
      <select
        value={date.month}
        onChange={(e) =>
          setDate({
            ...date,
            month: Number(e.target.value) as unknown as number,
          })
        }
        id={id}
        // placeholder={param}
      >
        {months.map((month) => (
          <option value={month}>{month}</option>
        ))}
      </select>
      <select
        value={date.year}
        onChange={(e) =>
          setDate({
            ...date,
            year: Number(e.target.value) as unknown as number,
          })
        }
        id={id}
        // placeholder={param}
      >
        {years.map((year) => (
          <option value={year}>{year}</option>
        ))}
      </select>
    </div>
  );
};

export const ZoozaFullNameInput = ({
  param,
  value,
  onChange,
  placeholder,
  id,
}: ZoozaInputProps) => {
  return (
    <div style={{ marginBottom: 5 }} className="z2 filter_item">
      <div className="z2 filter_control type_input">
        <input
          style={{ fontSize: 16, textAlign: "center" }}
          type={"text"}
          id={param}
          // placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export const ZoozaInput = ({
  param,
  value,
  onInputChange,
  placeholder,
}: ZoozaInputProps) => {
  return (
    <div style={{ marginBottom: 5 }} className="z2 filter_item">
      <div className="z2 filter_control type_input">
        <input
          style={{ fontSize: 16, textAlign: "center" }}
          type={"text"}
          id={param}
          // placeholder={placeholder}
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
};
