import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ZoozaContext } from "./zooza.context";
import { ZoozaContextTypes } from "../types/types";
import React from "react";

const Auth = ({ allowedRoles }: { allowedRoles: String[] }) => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const location = useLocation();

  return allowedRoles.includes(String(userData?.user?.role)) ? (
    <Outlet />
  ) : userData?.user.first_name + " " + userData?.user.last_name ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/register" state={{ from: location }} replace />
  );
};

export default Auth;
