import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow
} from "../../components/zooza-card-components";
import {t} from "i18next";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {Input} from "../../components/input";
import {InputNumber} from "../../components/add-payment-modal";
import {ZoozaSelect} from "../../components/inputs";
import {Member, Place, Room, ZoozaContextTypes} from "../../types/types";
import {Link} from "react-router-dom";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {EventDetail, ScheduleDetailResponse} from "../../types/schedule-type";
import {ZoozaContext} from "../../services/zooza.context";
import {ZoozaApi} from "../../services/Axios";
import {handleError} from "../../services/common-functions";
import { ReactComponent as Loader } from "../../assets/img/loader.svg";
import {Portal} from "react-overlays";

export const DateTimeCard = ({event, setEvent, members, places, schedules}: {
    event: EventDetail | undefined,
    setEvent: (event: EventDetail) => void,
    members: Member[] | undefined,
    places: Place[] | undefined,
    schedules: ScheduleDetailResponse | undefined

}) => {
    const { userData, courses } = useContext<ZoozaContextTypes>(ZoozaContext);
    const [inputNumber, setInputNumber] = useState<number | undefined>();
    const [place, setPlace] = useState<Place | undefined>(
        places?.find((p: Place) => p.id === event?.place_id)

    )
    const eventCourse = courses?.find((course) => course.id === event?.course_id);
    const [room, setRoom] = useState<Room | undefined>(
        place?.rooms?.find((room: Room) => room.id === event?.room_id)
    );
    const [pid, setPid] = useState<string>();
    const [member, setMember] = useState<any>(
        members?.find((m) => m.id === event?.trainer_id)
    );
    const [smallLoading, setSmallLoading] = useState(false);
    const [successSave, setSuccessSave] = useState("");
    const onMemberChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
        const member = members?.find(
            (member) => member.id === Number(e.target.value)
        );
        setMember(member);
    };

    const onDateChange = (date: any) => {
        setEvent({
            ...(event as EventDetail),
            date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        });
    };
    const onPlaceChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
        const [place_id, room_id] = e.target.value.split("_");
        const place = places?.find(
            (place) => Number(place.id) === Number(place_id)
        );
        if (Number(room_id) !== 0) {
            const room = place?.rooms?.find(
                (room) => Number(room.id) === Number(room_id)
            );
            setRoom(room);
        }
        setPid(e.target.value);
        setPlace(place);
    };
    const role = userData?.user.role;
    const getTrainers = async () => {
        try {
            const { data } = await ZoozaApi.get(
                "/users?page_size=5000&roles=owner|member|external_member|assistant|main_member"
            );
            setMember(data.results);
        } catch (error: any) {
            handleError(error.response.data);
        }
    };
    useEffect(() => {
        setPlace(
            places?.find(
                (place) => place.pid === `${event?.place_id}_${event?.room_id}`
            )
        );
        setPid(`${event?.place_id}_${event?.room_id}`);
        setMember(members?.find((member) => member.id === event?.trainer_id));
        setInputNumber(event?.duration);
        if (event?.room_id !== 0) {
            setRoom(place?.rooms?.find((room) => room.id === event?.room_id));
        }
    }, [event]);
    useEffect(() => {
        getTrainers();
    }, []);

    const saveEvent = async () => {
        try {
            setSmallLoading(true);

            await ZoozaApi.put(`/events/${event?.id}`, {
                date: moment(event?.date).isValid()
                    ? moment(event?.date).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                trainer_id: member.id,
                event_name: null,
                place_id: place?.id,
                room_id: room?.id || 0,
                duration: Number(inputNumber),
                time_string: moment(event?.date).isValid()
                    ? moment(event?.date).hours() * 60 + moment(event?.date).minutes()
                    : "",
            });
        } catch (error: any) {
            handleError(error.response.data);
            setSmallLoading(false);
            setSuccessSave("Nastala chyba, skúste to ešte raz.");
        } finally {
            setSmallLoading(false);
            setSuccessSave("Zmeny boli uložené.");
            setTimeout(() => {
                setSuccessSave("");
            }, 2000);
        }
    };

    return (
        <ZoozaCard>
            <ZoozaCardHeader
                title={t("global.title.datetime")}
                icon="icon-transform"
            ></ZoozaCardHeader>
            <ZoozaCardRow>
                <ZoozaCardCell>
            <span aria-label={t("global.title.date")}>
              {t("global.title.date")}
            </span>
                    <DatePicker
                        selected={
                            moment(event?.date).isValid()
                                ? moment(event?.date).toDate()
                                : null
                        }
                        onChange={(date) => {
                            onDateChange(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                        minDate={role === "owner" ? null : moment().toDate()}
                        popperContainer={CalendarContainer}
                        customInput={
                            <Input
                                value={moment(event?.date).format("HH:mm") || ""}
                                type={"text"}
                                placeholder={moment(event?.date).format("YYYY-MM-DD")}
                                id={"datepicker-input"}
                            />
                        }
                    />
                </ZoozaCardCell>
                <ZoozaCardCell>
            <span aria-label={t("global.title.time_from")}>
              {t("global.title.time_from")}
            </span>
                    <DatePicker
                        // selected={timeEventFrom ? moment(timeEventFrom, 'HH:mm').toDate() : null}
                        selected={moment(event?.date).toDate()}
                        showTimeSelect
                        onChange={(date) => {
                            onDateChange(date);
                        }}
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        popperContainer={CalendarContainer}
                        customInput={
                            <Input
                                value={moment(event?.date).format("HH:mm")}
                                type={"text"}
                                placeholder={moment(event?.date).format("HH:mm")}
                                id={"datepicker-input"}
                            />
                        }
                    />
                </ZoozaCardCell>
                <ZoozaCardCell>
            <span aria-label={t("global.title.duration")}>
              {t("global.title.duration")} (min)
            </span>
                    <InputNumber
                        label={false}
                        format={0}
                        stepper={5}
                        value={inputNumber || 0}
                        setValue={setInputNumber}
                    ></InputNumber>
                </ZoozaCardCell>
            </ZoozaCardRow>
            <ZoozaCardRow>
                <ZoozaCardCell>
            <span aria-label={t("event.title.place")}>
              {t("event.title.place")}
            </span>
                    <ZoozaSelect onChange={onPlaceChange} param="" value={pid}>
                        {places?.map((place: Place, i) => (
                            <optgroup key={i} label={place.name}>
                                <option value={place.pid}>{place.name}</option>
                                {place.rooms?.map((room: Room, i) => (
                                    <option key={i} value={room.pid}>
                                        {room.name}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </ZoozaSelect>
                </ZoozaCardCell>
                <ZoozaCardCell>
            <span aria-label={t("event.title.trainer_name")}>
              {t("event.title.trainer_name")}
            </span>
                    <ZoozaSelect value={member?.id} param="" onChange={onMemberChange}>
                        {members?.map((member, i) => (
                            <option key={i} value={member.id}>
                                {member.first_name + " " + member.last_name} ({member.email})
                            </option>
                        ))}
                    </ZoozaSelect>
                </ZoozaCardCell>
                <ZoozaCardCell>
            <span aria-label={t("global.title.course_name")}>
              {t("global.title.course_name")}
            </span>
                    <p>{event?.name}</p>
                </ZoozaCardCell>
            </ZoozaCardRow>
            <ZoozaCardActions>
                <button
                    className="z2 primary"
                    onClick={saveEvent}
                    disabled={smallLoading}
                >
                    {smallLoading ? (
                        <Loader className="spinner-button"></Loader>
                    ) : (
                        t("global.button.save")
                    )}
                </button>

                <Link
                    role="button"
                    className="z2 primary"
                    id="progress"
                    to={`/registrations/create/?time=${moment(event?.date).format(
                        "HH:mm"
                    )}&member=${event?.trainer_id}&place=${event?.place_id}_${
                        event?.room_id
                    }&date=${moment(event?.date).format("YYYY-MM-DD")}`}
                    state={{
                        skipSchedule: true,
                        course: eventCourse,
                        schedule: schedules,
                        fromEventPage: true,
                    }}
                >
                    {smallLoading ? (
                        <Loader className="spinner-button"></Loader>
                    ) : (
                        t("global.button.add_registration")
                    )}
                </Link>
                <h5>{successSave}</h5>
            </ZoozaCardActions>
        </ZoozaCard>
    )
}

const CalendarContainer = ({children}: ReactNode | any) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
};
