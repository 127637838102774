import React, { useContext, useEffect } from "react";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardCell,
  ZoozaCardHeader,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import { Registration } from "../../types/registration-types";
import moment from "moment";
import "moment/min/locales";
import { ZoozaApi } from "../../services/Axios";
import { useParams } from "react-router-dom";
import { handleError, msg } from "../../services/common-functions";
import { useAuthRole } from "../../services/AuthHook";
import { useTranslation } from "react-i18next";
const lang = localStorage.getItem("i18nextLng") || "en";
export const RegistrationComponent = ({
  registration,
}: {
  registration: Registration | undefined;
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [visibleNote, setVisibleNote] = React.useState({
    company: false,
    public: false,
  });
  const [notes, setNotes] = React.useState({
    company_note: registration?.company_note || "",
    public_note: registration?.public_note || "",
  });

  const toggleNote = (note: string) => {
    setVisibleNote({
      ...visibleNote,
      [note]: !visibleNote[note as keyof typeof visibleNote],
    });
  };
  const onSubmit = async () => {
    setVisibleNote({
      company: false,
      public: false,
    });
    try {
      const { data: response } = await ZoozaApi.put("/registrations/" + id, {
        company_note: notes.company_note,
        public_note: notes.public_note,
      });

      setNotes({
        company_note: response.company_note,
        public_note: response.public_note,
      });
    } catch (error: any) {
      handleError(error.respone.data, "alert");
      console.error("Error updating registration:", error);
    }
  };
  useEffect(() => {
    setNotes({
      company_note: registration?.company_note || "",
      public_note: registration?.public_note || "",
    });
  }, [registration]);
  useAuthRole({ roles: ["admin", "assitant"] });

  return (
    <ZoozaCard>
      <ZoozaCardHeader
        title={t("registration.registration.title.registartion")}
      />
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("registration.registration.title.created")}>
            {t("registration.registration.title.created")}
          </span>
          <p>
            {moment(registration?.created).locale(lang).format("dddd LL")}
            <br />
            {moment(registration?.created).locale(lang).fromNow()}
          </p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("registration.registration.title.status")}>
            {t("registration.registration.title.status")}
          </span>
          {msg(registration?.status || "")}
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("global.title.note")}>
            {t("global.title.private_note")}
          </span>
          {notes.company_note && !visibleNote.company && (
            <blockquote onClick={() => toggleNote("company")}>
              {notes?.company_note}
            </blockquote>
          )}

          <div>
            {visibleNote.company && (
              <>
                <textarea
                  value={notes.company_note}
                  name="company_note"
                  id="company_note"
                  onChange={(e) =>
                    setNotes({ ...notes, company_note: e.target.value })
                  }
                ></textarea>
                <button className="z2 primary" onClick={onSubmit}>
                  {t("global.button.save")}
                </button>
                <button className="z2" onClick={() => toggleNote("company")}>
                  {t("global.button.cancel")}
                </button>
              </>
            )}
            {!visibleNote.company && (
              <button onClick={() => toggleNote("company")} className="z2">
                {notes?.company_note
                  ? t("global.button.edit")
                  : t("global.button.add_note")}
              </button>
            )}
          </div>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("global.title.public_note")}>
            {t("global.title.public_note")}
          </span>
          {notes.public_note && !visibleNote.public && (
            <blockquote onClick={() => toggleNote("public")}>
              {notes?.public_note}
            </blockquote>
          )}
          <div>
            {visibleNote.public && (
              <>
                <textarea
                  value={notes.public_note}
                  name="note"
                  id="note"
                  onChange={(e) =>
                    setNotes({ ...notes, public_note: e.target.value })
                  }
                ></textarea>
                <button className="z2 primary" onClick={onSubmit}>
                  {t("global.button.save")}
                </button>
                <button className="z2" onClick={() => toggleNote("public")}>
                  {t("global.button.cancel")}
                </button>
              </>
            )}
            {!visibleNote.public && (
              <button onClick={() => toggleNote("public")} className="z2">
                {notes.public_note
                  ? t("global.button.edit")
                  : t("global.button.add_note")}
              </button>
            )}
          </div>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardActions>
        {" "}
        {/* <a href="#" role="button" className="z2 primary">
          {t("copy_registration")}
        </a> */}
        {/* <a href="#" role="button" className="z2 primary">
          {t("delete_registration")}
        </a> */}
      </ZoozaCardActions>
    </ZoozaCard>
  );
};
