import { Link } from "react-router-dom";
import { DailyAttendance } from "../types/schedule-type";
import moment from "moment";
import { Member, Place, CalendarEvent } from "../types/types";
import React, { useEffect, useState } from "react";

type Props = {
  event: CalendarEvent;
  attendances: DailyAttendance[] | undefined;
  places: Place[] | undefined;
  times: any[];
};

export const CalendarEventItem = ({
  event,
  attendances,
  places,
  times,
}: Props) => {
  const [paymentStatus, setPaymentStatus] = useState("unpaid");

  const eventTimeHours = Number(moment(event.date_time).format("HH"));

  const eventTimeMinutes = Number(moment(event.date_time).format("mm"));

  const isMobile = window.innerWidth < 600;
  const cellHeight = isMobile ? 75 : 100;
  const cellWidth = isMobile ? window.innerWidth - 50 : 400;
  const topOffset = 60;
  const leftSet = isMobile ? 50 : 100;
  const movePostionTop =
    eventTimeHours * cellHeight +
    (cellHeight / 60) * eventTimeMinutes +
    topOffset;
  const movePostionLeft = leftSet;
  const roomPosition = Number(
    places?.findIndex(
      (place) => place?.id === event.place_id || place.id === event.room_id
    )
  );
  console.log(window.innerWidth - 50);
  const height = (event.duration * cellHeight) / 60;

  const movePostionLeftRoom = cellWidth * roomPosition + leftSet;
  const isRoom = Boolean(event.room_id);
  const start = moment(event.date_time);
  const end = start.clone().add(event.duration, "minutes");
  const width = (cellWidth * event.duration) / 60;
  const attendance = attendances?.find(
    (attendance) => attendance.event_id === event.id
  );

  return (
    <Link style={{ zIndex: 1000 }} to={`${event.id}`}>
      <li
        key={event.date_time}
        className={"z2 event" + " " + event.course_color}
        style={{
          top: !isRoom ? movePostionTop : movePostionTop,
          left: movePostionLeftRoom,
          height: height >= cellHeight ? height : cellHeight,
          width: width,
          transition: "ease 0.3s",
        }}
      >
        <div className="event__title" key={attendance?.full_name}>
          <div
            className="status-circle"
            style={{
              background:
                paymentStatus === "paid"
                  ? "#0cc429"
                  : paymentStatus === "partially_paid"
                  ? "#ffbf00"
                  : "#ff3000",
            }}
          ></div>
          {/* <div className="event-no"></div> */}
          {attendance?.ef_full_name
            ? attendance?.ef_full_name
            : attendance?.full_name}{" "}
          <br />
          <span className="icon-layer"></span> {event.registrations} |{" "}
          <span className="icon-paste"></span> {event.attended} |{" "}
          {event.event_number && event.total_events && (
            <span className="icon-calendar"></span>
          )}{" "}
          {event?.event_number ? event.event_number : 0} /{" "}
          {event?.total_events ? event.event_number : 0}{" "}
          {attendance?.ef_extra_field_1}
          <br />
          {event.course_name}
          {event.course_name ? <br /> : ""}
          {event.schedule_name} {event.schedule_name ? <br /> : ""}
          {/* {event.time} - {moment(end).format("HH:mm")} ({event.duration}min) */}
        </div>
      </li>
    </Link>
  );
};
