import React, { useContext, useState } from "react";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow,
} from "../../components/zooza-card-components";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { CreateRegistrationContextTypes, ErrorType } from "../../types/types";
import { CreateRegistrationContext } from "./create-registration-context";
import { ReactComponent as Loader } from "../../assets/img/loader.svg";

import { PaymentModalCreation } from "./payment-modal-creation";
import { InvoiceOptionsModal } from "../payment-page/invoice-component";
import { ZoozaApi } from "../../services/Axios";
import moment from "moment";

import { LoadingButton } from "../../components/loading-button";
import { useTranslation } from "react-i18next";
import { handleError } from "../../services/common-functions";

type FetchingCard = {
    progress: number;
    progressing: (e: any) => void;
};

type Options = {
    date: string;
    paymentType: string;
    note: string;
    paymentProfile?: number;
};

export const FetchingCard = ({ progress, progressing }: FetchingCard) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const condition = location?.state?.skipSchedule;
    const [fromEventPage, setFromEventPage] = useState(false);

    const {
        fetchProgress,
        fetchedRegistrations,
        handleSubmit,
        course,
        handleRegistartionsCreate,
        disableSubmit
    } = useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
    const [reigistrationIdForPayment, setReigistrationIdForPayment] = useState<
        number | string | undefined
    >();

    const [openPayment, setOpenPayment] = useState<boolean>(false);
    const [openInvoice, setOpenInvoice] = useState<boolean>(false);
    const [paymentResposnse, setPaymentResposnse] = useState<Paymentresponse[]>();
    const [invoices, setInvoices] = useState<Invoice[]>();
    const [invoiceError, setInvoiceError] = useState<ErrorType>();
    const [options, setOptions] = useState<Options>({
        date: moment().format("YYYY-MM-DD"),
        paymentType: "ina",
        note: "",
    });

    const [invoiceLinkGenerating, setInvoiceLinkGenerating] = useState(false);
    const [invoiceGenerating, setInvoiceGenerating] = useState(false);
    const getInvoices = async () => {
        const { data: invoice } = await ZoozaApi.get(
            `/customer_invoices/?order_id=${reigistrationIdForPayment}&order_type=course`
        );
        if (invoices) {
            setInvoices([...invoices, invoice]);
        } else {
            setInvoices([invoice]);
        }
    };
    const onGenerateInvoice = async () => {
        setInvoiceGenerating(true);
        try {
            const { data } = await ZoozaApi.post("/customer_invoices", {
                order_id: reigistrationIdForPayment,
                order_type: "course",
                skip_queue: true,
                options: {
                    date: options.date,
                    text: options.note,
                    payment_method: options.paymentType,
                },
            });
            if (data === true) {
                setInvoiceGenerating(false);
            } else if (typeof data === "number") {
                try {
                    await ZoozaApi.get(`/customer_invoices/${data}`);
                } catch (e: any) {
                    console.error(e);
                    setInvoiceError(e.response.data as ErrorType);
                    setInvoiceGenerating(false);
                }
            }
            getInvoices();
        } catch (e: any) {
            handleError(e.response.data, "alert");
            setInvoiceError(e.response.data as ErrorType);
        }
        setOpenInvoice(false);
        setInvoiceGenerating(false);
    };
    const onGenerateInvocieLink = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any
    ) => {
        setInvoiceLinkGenerating(true);
        const id = e.target.id;

        try {
            const { data } = await ZoozaApi.get(`/customer_invoices/${id}/download`);

            window.open(data.url, "_blank");
        } catch (e) {
            console.error(e);
            setInvoiceError(e as ErrorType);
        }
        setInvoiceLinkGenerating(false);
    };

    return (
        <>
            <ZoozaCard isDisabeld={progress !== 4}>
                <ZoozaCardHeader title={t("registration.create.card.submit.title")} />
                {progress === 4 && (
                    <>
                        {!condition && <><ZoozaCardRow>
                            <ProgressCell
                                progress={fetchProgress}
                                value={1}
                                progressing={t("registration.create.card.submit.progress.schedules")}
                                after={t("registration.create.card.submit.created.schedules")}
                                before={t("registration.create.card.submit.will_create.schedules")} />
                        </ZoozaCardRow><ZoozaCardRow>
                                <ProgressCell
                                    progress={fetchProgress}
                                    value={2}
                                    progressing={t("registration.create.card.submit.progress.events")}
                                    after={t("registration.create.card.submit.created.events")}
                                    before={t("registration.create.card.submit.will_create.events")} />
                            </ZoozaCardRow></>}

                        <ZoozaCardRow>
                            <ProgressCell
                                progress={fetchProgress}
                                value={3}
                                progressing={t(
                                    "registration.create.card.submit.progress.registrations"
                                )}
                                after={t(
                                    "registration.create.card.submit.created.registrations"
                                )}
                                before={t(
                                    "registration.create.card.submit.will_create.registrations"
                                )}
                            />
                        </ZoozaCardRow>
                        {fetchProgress === 4 && (
                            <>
                                <ZoozaCardRow>
                                    <ZoozaCardCell>
                                        <div>
                                            <span className="z2">
                                                {t("registration.create.card.submit.done")}
                                            </span>
                                        </div>
                                    </ZoozaCardCell>
                                </ZoozaCardRow>

                                {fetchedRegistrations?.registrations.map(
                                    (registration: number, index: number) => {
                                        const paymentResponses = paymentResposnse?.filter(
                                            (res: Paymentresponse) => res.order_id === registration
                                        );

                                        // Find the invoice corresponding to the current registration ID
                                        const invoice = invoices?.find(
                                            (invoice) => invoice.data[0].order_id === registration
                                        );

                                        return (
                                            <ZoozaCardRow key={registration}>
                                                <ZoozaCardCell>
                                                    <div style={{ alignItems: "start" }}>
                                                        <div
                                                            style={{
                                                                alignItems: "center",
                                                                alignContent: "center",
                                                            }}
                                                        >
                                                            <button
                                                                style={{ marginRight: 10 }}
                                                                onClick={() => {
                                                                    setOpenPayment(true);
                                                                    setReigistrationIdForPayment(registration);
                                                                }}
                                                                className="z2 primary"
                                                            >
                                                                {index +
                                                                    1 +
                                                                    "." +
                                                                    " " +
                                                                    t(
                                                                        "registration.create.card.submit.button.add_payment"
                                                                    )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ZoozaCardCell>
                                                {paymentResponses?.find(
                                                    (res) => res.order_id === registration
                                                ) && (
                                                        <ZoozaCardCell>
                                                            <div style={{ alignItems: "start" }}>
                                                                <div
                                                                    style={{
                                                                        alignItems: "center",
                                                                        alignContent: "center",
                                                                    }}
                                                                >
                                                                    <button className={"z2"} onClick={() => {
                                                                        setOpenInvoice(true);
                                                                        setOptions({ ...options, note: course?.name || "" })
                                                                    }}>

                                                                        {index +
                                                                            1 +
                                                                            "." +
                                                                            " " +
                                                                            t(
                                                                                "registration.create.card.submit.button.generate_invoice"
                                                                            )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </ZoozaCardCell>
                                                    )}
                                                {invoice && (
                                                    <ZoozaCardCell>
                                                        <div>
                                                            <LoadingButton
                                                                name={t(
                                                                    "registration.create.card.submit.button.download_invoice"
                                                                )}
                                                                loading={invoiceLinkGenerating}
                                                                class="z2"
                                                                id={String(invoice?.data[0].id)}
                                                                onClick={onGenerateInvocieLink}
                                                            />
                                                        </div>
                                                    </ZoozaCardCell>
                                                )}
                                            </ZoozaCardRow>
                                        );
                                    }
                                )}
                            </>
                        )}

                        <ZoozaCardActions>
                            {fetchProgress !== 4 && (
                                <button
                                    onClick={
                                        condition
                                            ? handleRegistartionsCreate
                                            : handleSubmit
                                    }
                                    id="progress"
                                    value={5}
                                    className="z2 primary"
                                    disabled={disableSubmit}
                                >
                                    {t("registration.create.card.submit.button.submit")}
                                </button>
                            )}
                            <button
                                className="z2"
                                id="progress"
                                value={3}
                                onClick={progressing}
                            >
                                {t("registration.create.card.submit.button.back")}
                            </button>
                            {fetchProgress === 4 && (
                                <>
                                    <Link
                                        role="button"
                                        to={`/registrations/${Number(fetchedRegistrations?.registrations?.length) > 0
                                                ? fetchedRegistrations?.registrations?.[0]
                                                : ""
                                            }`}
                                        className="z2 primary"
                                    >
                                        {t(
                                            "registration.create.card.submit.button.redirect_to_registration"
                                        )}
                                    </Link>
                                    <Link role="button" to={`/calendar`} className="z2">
                                        {t(
                                            "registration.create.card.submit.button.redirect_to_calendar"
                                        )}
                                    </Link>
                                </>
                            )}
                        </ZoozaCardActions>
                    </>
                )}
            </ZoozaCard>
            <PaymentModalCreation
                open={openPayment}
                setOpen={setOpenPayment}
                order_id={Number(reigistrationIdForPayment)}
                course={course}
                setRes={setPaymentResposnse}
                res={paymentResposnse}
            />
            <InvoiceOptionsModal
                open={openInvoice}
                setOpen={setOpenInvoice}
                onSubmit={onGenerateInvoice}
                setOptions={setOptions}
                options={options}
                error={invoiceError}
                loading={invoiceGenerating}
                billing_profile={0}
            />
        </>
    );
};
const ProgressCell = ({
    progress,
    value,
    before,
    progressing,
    after,
}: {
    progress: number | undefined;
    value: number;
    before: string;
    progressing: string;
    after: string;
}) => {
    return (
        <ZoozaCardCell>
            {Number(progress) > value ? (
                <span className="z2">
                    {after}
                    <em className="icon-checklist"></em>
                </span>
            ) : (
                <span className="z2">
                    {progress === value ? (
                        <span>
                            {progressing} <Loader className="spinner-button" />
                        </span>
                    ) : (
                        <span>{before}</span>
                    )}
                </span>
            )}
        </ZoozaCardCell>
    );
};

export type Paymentresponse = {
    order_id: number;
    order_type: string;
    variable_symbol: number;
    amount: number;
    note: string;
    transaction_type: string;
    origin: string;
    posting_date: string;
    value_date: string;
    payment_processed: boolean;
};

export interface Invoice {
    total: number;
    page: number;
    page_size: number;
    settings: Settings;
    data: Date[];
}

export interface Date {
    id: number;
    invoice_no: number;
    order_id: number;
    order_type: string;
    company_id: number;
    faktury_online_code: string;
    invoice_data: InvoiceData;
    box_id: number;
    faktury_online_error_code: number;
    options: InvoiceOptions;
    created: string;
    updated: string;
}

export interface InvoiceData {
    d: D;
    o: O;
    f: F;
    p: P[];
    key: string;
    email: string;
    apitest: number;
}

export interface D {
    d_name: string;
    d_street: string;
    d_city: string;
    d_state: string;
    d_zip: number;
    d_ico: number;
    d_dic: number;
    d_icdph: string;
    d_vatpayer: number;
    d_viewpayer: number;
    d_iban: string;
    d_email: string;
    d_web: string;
    d_phone: string;
}

export interface F {
    f_number: number;
    f_vs: number;
    f_qr: number;
    f_paid: string;
    f_currency: string;
    f_language: string;
    f_date_issue: string;
    f_date_delivery: string;
    f_payment: string;
}

export interface O {
    o_name: string;
    o_street: string;
    o_email: string;
    o_phone: string;
}

export interface P {
    p_text: string;
    p_quantity: number;
    p_unit: string;
    p_vat: string;
    p_pricevat: number;
}

export interface InvoiceOptions {
    date: string;
    text: string;
    payment_method: string;
}

export interface Settings {
    company_id: number;
}
