import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScheduleProductsType } from "../../types/schedule-type";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardHeader,
} from "../../components/zooza-card-components";
import { Registration } from "../../types/registration-types";
import { ZoozaApi } from "../../services/Axios";
import { ReactComponent as Loader } from "../../assets/img/loader.svg";
import { LoadingButton } from "../../components/loading-button";

export const ScheduleProducts = ({
  products,
  registration,
  open,
  setOpen,
  setProductToPay,
  getSchedueProducts,
}: {
  products: ScheduleProductsType[];
  registration: Registration | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  setProductToPay: (product: ScheduleProductsType | undefined) => void;
  getSchedueProducts: (schedule_id: number) => void;
}) => {
  const [resProducts, setProducts] = useState<any[]>([]);
  const [product, setProduct] = useState<any>();
  const [loading, setLoading] = useState({ products: false, product: false });
  const findProduct = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // prevent the default action of the link
    const target = e.target as HTMLAnchorElement; // type assertion
    const id = target.id;
    console.log(id);
    const product = products.find(
      (product) => product.product.id === Number(id)
    );
    setProductToPay(product);
    setOpen(true);
  };

  const { t } = useTranslation();

  const handleGetProducts = async () => {
    setLoading({ product: false, products: true });
    try {
      const { data: products } = await ZoozaApi.get(
        `/products?page=0&type=all&page_size=1000&1=1`
      );
      setProducts(products.data);
      setProduct(products.data[0]);
      setLoading({ product: false, products: false });
    } catch (e) {
      console.error(e);
      setLoading({ product: false, products: false });
    }
  };

  const handleSetProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLSelectElement;
    const id = target.value;

    const product = resProducts.find(
      (product) => Number(product.id) === Number(id)
    );
    setProduct(product);
  };

  const handleUpdateProduct = async () => {
    setLoading({ product: true, products: false });
    try {
      await ZoozaApi.post(`/schedules/${registration?.schedule_id}/products`, {
        products: [{ id: product.id, settings: { purchase_limit: 0 } }],
      });
      getSchedueProducts(Number(registration?.schedule_id));
      setLoading({ product: false, products: false });
    } catch (e) {
      console.error(e);
      setLoading({ product: false, products: false });
    }
  };
  return (
    <>
      <ZoozaCard>
        <ZoozaCardHeader
          icon="icon-crown"
          title={t("registration.title.products")}
        />
        <table>
          <thead>
            <tr>
              <th>
                <span
                  aria-label={t(
                    "registration.title.available_products_for_registration"
                  )}
                >
                  {t("registration.title.available_products_for_registration")}
                </span>
              </th>

              <th>
                <span aria-label="">{""}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr>
                <td className="">
                  <span
                    aria-label={t(
                      "registration.title.available_products_for_registration"
                    )}
                  >
                    {t(
                      "registration.title.available_products_for_registration"
                    )}
                  </span>
                  <p>{product.product.name}</p>
                </td>

                <td>
                  <span aria-label={t("registration.title.buy_product")}></span>

                  <a id={String(product.product.id)} onClick={findProduct}>
                    {t("registration.title.buy_product")}
                  </a>
                </td>
              </tr>
            ))}
            {resProducts.length > 0 && (
              <tr>
                <td>
                  <select onChange={handleSetProduct}>
                    {resProducts.map((product) => (
                      <option value={product.id}>{product.name}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <span aria-label={t("registration.title.select_product")}>
                    {t("registration.title.select_product")}
                  </span>
                  {loading.product ? (
                    <Loader className="spinner-button" />
                  ) : (
                    <a onClick={handleUpdateProduct}>
                      {t("registration.title.add_product")}{" "}
                    </a>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <ZoozaCardActions>
          {resProducts.length === 0 && (
            <LoadingButton
              loading={loading.products}
              class="z2 primary"
              onClick={handleGetProducts}
              primary
            >
              {t("registration.button.add_product")}
            </LoadingButton>
          )}
        </ZoozaCardActions>
      </ZoozaCard>
    </>
  );
};
