import React, { useContext, useEffect, useState } from "react";
import { Modal } from "../modal";
import {
  Event,
  Registration,
  RegistrationResult,
} from "../../types/registration-types";
import { ZoozaApi } from "../../services/Axios";
import { convertToCurrency } from "../../services/curency-service";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import moment from "moment";
import { lang } from "../language-selector";
import { ZoozaSelect } from "../inputs";
import { InputNumber } from "../add-payment-modal";
import i18n from "../../locale";
import { handleError } from "../../services/common-functions";

export const DeleteEventModal = ({
  setOpen,
  event,
  paid,
  toPay,
  registration_id,
  getEvents,
  totalEvents,
}: {
  setOpen: (event: Event | null) => void;
  event: Event | undefined | null;
  paid?: number;
  toPay?: number;
  registration_id?: number;
  totalEvents: number | undefined;
  getEvents?: () => void;
}) => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [registrations, setRegistrations] = useState<RegistrationResult[]>([]);
  const [refund, setRefund] = useState(0);
  const [notify, setNotify] = useState(true);
  const [registration, setRegistration] = useState<RegistrationResult>();
  const [loading, setLoading] = useState(false);
  const onClose = () => {
    setOpen(null);
  };
  const getRegistrations = async () => {
    try {
      const { data } = await ZoozaApi.get(
        `/registrations?advanced_search=1&schedule_id=${event?.schedule_id}&status=active`
      );
      setRegistrations(data.results);
      return data;
    } catch (err: any) {
      handleError(err.response.data);
    }
  };

  useEffect(() => {
    if (!registration_id) {
      getRegistrations();
    }
  }, [event]);

  const deleteEventfromApi = async () => {
    try {
      const { data } = await ZoozaApi.put(`/events/${event?.id}`, {
        status: "deleted",
        notify: notify,
      });
    } catch (err: any) {
      handleError(err?.response?.data, "alert");
    }
  };
  const onChangeregistration = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const registration = registrations?.find(
      (registration) => registration.registration_id === Number(e.target.value)
    );
    setRegistration(registration);
  };
  const refundPayment = async () => {
    try {
      const { data: paymentData } = await ZoozaApi.post("/payments", [
        {
          order_id: registration?.registration_id || registration_id,
          amount: refund,
          transaction_type: "refund",
          variable_symbol: registration?.registration_id || registration_id,
          posting_date: moment().format("YYYY-MM-DD"),
          value_date: moment().format("YYYY-MM-DD"),
          origin: "manual",
          order_type: "course",
        },
      ]);
    } catch (err: any) {
      handleError(err.response.data, "alert");
    }
  };
  const deleteRefundEvent = async () => {
    setLoading(true);
    if (refund > 0) {
      refundPayment();
    }
    await deleteEventfromApi();
    await setLoading(false);
    setOpen(null);
    await getEvents?.();
  };
  const defaultValue = (Number(paid) + Number(toPay)) / Number(totalEvents);
  useEffect(() => {
    setRefund(defaultValue);
  }, [paid, toPay, totalEvents]);
  useEffect(() => {
    if (refund > Number(paid)) {
      // alert(i18n.t("alert.error.delete_event.refund_max_value_reached"));
      setRefund(Number(paid));
    }
  }, [refund]);
  const onChangeRefund = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > Number(paid)) {
      // alert(i18n.t("alert.error.delete_event.refund_max_value_reached"));
      return;
    } else {
      setRefund(Number(e.target.value));
    }
  };

  return (
    <Modal
      isOpen={event ? true : false}
      onClose={onClose}
      onSubmit={deleteRefundEvent}
      submitButton={i18n.t("global.button.delete")}
      cancelButton={i18n.t("global.button.cancel")}
      title={i18n.t("modal.delete_event.title.title")}
      loading={loading}
    >
      <form style={{ padding: 10 }} action="">
        <h5>{i18n.t("global.title.date")}</h5>
        <p>{moment(event?.date).locale(lang).format("LLLL")}</p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
          }}
        >
          <div>
            <h6>{i18n.t("global.title.paid")}</h6>
            <p>{convertToCurrency(paid, userData?.company.region)}</p>
          </div>
          <div>
            <h6>
              {Number(toPay) > 0
                ? i18n.t("enums.payment.status.overpaid")
                : i18n.t("modal.delete_event.title.to_pay")}
            </h6>
            <p>{convertToCurrency(toPay, userData?.company.region)}</p>
          </div>
        </div>

        <h5>{}</h5>
        <InputNumber
          label={i18n.t("global.title.refund")}
          value={refund}
          setValue={setRefund}
          onChangeValue={onChangeRefund}
        />
        <br />
        <br />
        <label htmlFor="notify">
          {" "}
          {i18n.t("modal.delete_event.notify_delete")}
        </label>
        <br />
        <label htmlFor="notify_yes">
          {i18n.t("global.title.yes")}
          <input
            onClick={() => setNotify(true)}
            checked={notify}
            id="notify_yes"
            type="radio"
          />
        </label>
        <label htmlFor="notify_no">
          {i18n.t("global.title.no")}
          <input
            onClick={() => setNotify(false)}
            checked={!notify}
            id="notify_no"
            type="radio"
          />
        </label>
        <br />
        <br />

        {!registration_id && (
          <label htmlFor="registrations">
            {i18n.t("modal.delete_event.title.registrations")}
            <br />
            <ZoozaSelect
              param=""
              value={registration?.registration_id}
              id="registration"
              onChange={onChangeregistration as any}
            >
              {registrations?.map((registration) => (
                <option value={registration.registration_id}>
                  {registration?.full_name}( {registration?.email})
                </option>
              ))}
            </ZoozaSelect>
          </label>
        )}
      </form>
    </Modal>
  );
};
