import React from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { Registration } from "../../types/registration-types";
import { useTranslation } from "react-i18next";
import {Order} from "../../types/orders-types";

export const ClientComponent = ({
  registration,
}: {
  registration: Registration| Order | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <ZoozaCard>
      <ZoozaCardHeader
        icon="icon-user-female"
        title={t("registartion.client.header_title")}
      />
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("registartion.client.client_name")}>
            {t("registartion.client.client_name")}
          </span>
          <p>{registration?.__users__full_name? registration?.__users__full_name: (registration as Order)?.user?.first_name +(registration as Order)?.user.last_name  }</p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("registartion.client.client_email")}>
            {t("registartion.client.client_email")}
          </span>
          <p>{registration?.__users__email?registration?.__users__email: (registration as Order)?.user?.email }</p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("registartion.client.client_phone")}>
            {t("registartion.client.client_phone")}
          </span>
          <p>{registration?.__users__phone? registration?.__users__phone :(registration as Order)?.user?.phone }</p>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardActions>{""}</ZoozaCardActions>
    </ZoozaCard>
  );
};
