import moment from "moment";
import { Moment } from "moment";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZoozaContext } from "../../services/zooza.context";
import { Place, Room, ZoozaContextTypes } from "../../types/types";
import { Event } from "../../types/registration-types";
import { useSearchParams } from "react-router-dom";
import {
  ZoozaTableCell,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../zooza-table-components";
import React from "react";
import { Input } from "../input";
import { Portal } from "react-overlays";
import { lang } from "../language-selector";
import { ZoozaSelect } from "../inputs";
import DatePicker from "react-datepicker";

export const EventRow = ({
  event,
  eventChange,
  newEvent,
  setEvents,
  setDeleteModal,
}: {
  eventChange: (
    param: string,
    value: number | Date | null | Moment | string,
    id?: number,
    setEvents?: any
  ) => void;
  event: Event | any;
  newEvent?: boolean;
  setEvents?: any;

  setDeleteModal?: (event: Event) => void;
}) => {
  const { places, members } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { t } = useTranslation();
  const [date, setDate] = useState<any>(
    moment(event.date).format("YYYY-MM-DD")
  );
  const [member, setMember] = useState<any>(
    members?.find((m) => m.id === event.trainer_id)
  );
  const [pid, setPid] = useState<any>(
    places?.find((place) => place.id === event.place_id)?.pid
  );

  const deleteEvent = async () => {
    if (event.id && event.new !== true) {
      setDeleteModal?.(event);
    } else {
      setEvents((prevEvents: Event[] | undefined) => {
        if (!prevEvents) {
          return [];
        }
        return prevEvents.filter((e) => e.id !== event.id);
      });
    }
  };
  const onMemberChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const member = members?.find(
      (member) => member.id === Number(e.target.value)
    );
    setMember(member);
    eventChange("trainer_id", member?.id || 0, event?.id);
  };
  const onPlaceChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    setPid(e.target.value);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const memberParam = searchParams.get("member");
    const placeParam = searchParams.get("place");

    if (memberParam || placeParam) {
      const selectedMember = members?.find(
        (member) => member.id === Number(memberParam)
      );
      if (placeParam) {
        if (selectedMember) {
          setMember(selectedMember);
          setPid(placeParam);
        }
      } else if (selectedMember) {
        setMember(selectedMember);
      }
    } else {
      setMember(members?.find((member) => member.id === event.trainer_id));
      setPid(`${event.place_id}_${event.room_id}`);
    }
  }, []);

  useEffect(() => {
    const place = places?.find(
      (place) => place.id === Number(pid?.split("_")[0])
    );
    const room = place?.rooms?.find(
      (room) => room.id === Number(pid?.split("_")[1])
    );
    eventChange("place_id", place?.id || 0, event?.id);
    if (room) {
      eventChange("room_id", room?.id || 0, event?.id);
    }
  }, [pid]);

  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);

  const [role, setRole] = useState<any>();

  useEffect(() => {
    const role = userData?.user.role;
    setRole(role);
  }, []);
  useEffect(() => {
    if (!member) {
      eventChange("trainer_id", Number(members?.[0]?.id), event?.id);
    }
    if (!event.place_id) {
      eventChange("place_id", Number(places?.[0]?.id), event?.id);
    }
  }, [members]);

  return (
    <ZoozaTableRow key={event.id}>
      <ZoozaTableRowItem
        style={{
          background:
            event?.avability && event?.avability?.conflicts?.length !== 0
              ? "#ffbf00"
              : event.new
              ? "#0cc429"
              : "",
        }}
      >
        <ZoozaTableCell span="2">
          <span aria-label={t("global.title.date")}>
            {t("global.title.date")}
          </span>
          <DatePicker
            value={moment(event.date).format("YYYY-MM-DD")}
            locale={lang}
            selected={moment(event.date).toDate()}
            minDate={role === "owner" ? null : moment().toDate()}
            popperContainer={CalendarContainer}
            onChange={(date) => {
              if (moment(date).isValid()) {
                eventChange("date", date, event?.id);
              }
            }}
            customInput={
              <Input
                value={moment(event.date).format("HH:mm") || ""}
                type={"text"}
                placeholder={moment(event.date).format("YYYY-MM-DD")}
                id={"datepicker-input"}
              />
            }
          />
        </ZoozaTableCell>
        <ZoozaTableCell span="2">
          <span aria-label={t("global.time.from")}>
            {t("global.time.from")}
          </span>
          <DatePicker
            selected={moment(event.date).toDate()}
            showTimeSelect
            locale={lang}
            tabIndex={-1}
            onChange={(date) => {
              eventChange("date", date, event?.id);
            }}
            value={moment(event.date).format("HH:mm")}
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            popperContainer={CalendarContainer}
            customInput={
              <Input
                value={moment(event.date).format("HH:mm") || ""}
                type={"text"}
                placeholder={moment(event.date).format("YYYY-MM-DD")}
                id={"datepicker-input"}
              />
            }
          />
        </ZoozaTableCell>
        <ZoozaTableCell span="2">
          <span aria-label={t("global.time.to")}>{t("global.time.to")}</span>
          <DatePicker
            selected={
              moment(event.date).add(event.duration, "minutes").toDate() ||
              moment().toDate()
            }
            locale={lang}
            showTimeSelect
            onChange={(duration) =>
              eventChange(
                "duration",
                moment(duration).diff(moment(event.date), "minutes"),
                event.id
              )
            }
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            popperContainer={CalendarContainer}
            customInput={
              <Input
                value={moment(date).format("HH:mm")}
                onChange={(date: any) => setDate(date)}
                type={"text"}
                placeholder={moment(date).format("YYYY-MM-DD")}
                id={"datepicker-input"}
              />
            }
          />
        </ZoozaTableCell>
        {!newEvent && (
          <ZoozaTableCell span="3">
            <span aria-label={t("global.trainer.name")}>
              {t("global.trainer.name")}
            </span>

            <ZoozaSelect value={member?.id} param="" onChange={onMemberChange}>
              {members?.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.first_name + " " + member.last_name}
                </option>
              ))}
            </ZoozaSelect>
          </ZoozaTableCell>
        )}
        {!newEvent && (
          <ZoozaTableCell span="2">
            <span aria-label={t("global.place.name")}>
              {t("global.place.name")}
            </span>
            <ZoozaSelect onChange={onPlaceChange} param="" value={pid}>
              {places?.map((place: Place) => (
                <optgroup key={place.id} label={place.name}>
                  <option value={place.pid}>{place.name}</option>
                  {place.rooms?.map((room: Room) => (
                    <option key={room.id} value={room.pid}>
                      {room.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </ZoozaSelect>
          </ZoozaTableCell>
        )}

        <ZoozaTableCell span="1">
          <div>
            {userData?.user.role === "owner" || event.new ? (
              <button onClick={deleteEvent} className="z2 ">
                {" "}
                <span className="icon-cross"></span>
              </button>
            ) : (
              moment(event.date).isAfter() && (
                <button onClick={deleteEvent} className="z2 ">
                  {" "}
                  <span className="icon-cross"></span>
                </button>
              )
            )}
          </div>
        </ZoozaTableCell>

        {event?.avability &&
          event?.avability?.conflicts?.map((conflict: any) => (
            <ZoozaTableCell span="12">
              <div className=" note">
                <div style={{ display: "flex" }}>
                  <p className="z2">
                    {conflict.conflict} <br />{" "}
                    {moment(conflict.start).locale(lang).format("LLLL")} -{" "}
                    {moment(conflict.end).locale(lang).format("dddd, HH:mm")}
                  </p>{" "}
                  <p></p>
                </div>
              </div>
            </ZoozaTableCell>
          ))}
      </ZoozaTableRowItem>
    </ZoozaTableRow>
  );
};
const CalendarContainer = ({ children }: ReactNode | any) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};
