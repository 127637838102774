import React, { useContext, useEffect, useState } from "react";
import { ZoozaApi } from "../../services/Axios";
import moment, { Moment } from "moment";
import {
  DashboardPaymentsResponse,
  PaymentInsights,
} from "../../types/payments-types";
import {
  ZoozaTable,
  ZoozaTableBody,
  ZoozaTableCell,
  ZoozaTableHeader,
  ZoozaTablePagination,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../../components/zooza-table-components";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "../../components/date-picker";
import { ZoozaSelect } from "../../components/inputs";
import { useSearchParams } from "react-router-dom";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardCell,
  ZoozaCardHeader,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import { lang } from "../../components/language-selector";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import { convertToCurrency } from "../../services/curency-service";
import { msg } from "../../services/common-functions";
import useDebounce from "../../hooks/debounce-search";
import { ReactComponent as Loader } from "../../assets/img/loader.svg";
import i18next from "../../locale";
const isMobile = window.innerWidth < 600;

export const PaymentsTable = () => {
  const today = moment();
  const { t } = useTranslation();
  const { currency } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [payments, setPayments] = useState<DashboardPaymentsResponse>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [date, setDate] = useState<Moment>(today);
  const [search] = useSearchParams();
  const [insights, setInsights] = useState<PaymentInsights>();
  const [transactionType, setTransactionType] = useState<string>(
    "credit|CREDIT|credit_via_transfer|CREDIT_VIA_TRANSFER|TATRAPAY|VIAMO|SPOROPAY|cardpay|VUBPAY|POSTAPAY|FIRSTDATAONDEMAND"
  );
  const [colors, setColor] = useState<string[]>([""]);
  const [loading, setLoading] = useState(false);
  const searchColor = useDebounce(colors, 600);
  console.log(transactionType)
  const options = {
    vs: "",
    origin: "all",
    payment_status: "all",
    order_type: "all",
    transaction_type: transactionType,
    page: String(page - 1),
    page_size: String(perPage),
    sort_by: "created_desc",
    start: date.format("YYYY-MM-DD"),
    end: date.format("YYYY-MM-DD"),
    colors: colors.join(",").replace(/,/g, "|"),
  };

  const getPayments = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams(options).toString();

      const { data } = await ZoozaApi.get(`/payments?${params}&1=1`);

      setPayments(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching payments:", error);
    }
  };
  const getInsightPayments = async () => {
    try {
      const optionsParam = encodeURIComponent(JSON.stringify(options));

      const { data } = await ZoozaApi.get(
        `/reports/insights?type=payments&options=${optionsParam}&1=1`
      );
      setInsights(data.total);
      // rest of your code...
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };
  useEffect(() => {
    getPayments();
    getInsightPayments();
  }, [page, perPage, date, transactionType, searchColor]);
  const onChangeMethod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "credit") {
      setTransactionType("credit|CREDIT");
    } else if (e.target.value === "cardpay") {
      setTransactionType(
        "cardpay|POSTAPAY|FIRSTDATAONDEMAND|VUBPAY|TAATRAPAY|VIAMO|SPOROPAY|STRIPE"
      );
    } else if (e.target.value === "transfer") {
      setTransactionType(
        "credit_via_transfer|zooza_online_transfer_percentage"
      );
    } else {
      setTransactionType(
        "credit|CREDIT|credit_via_transfer|CREDIT_VIA_TRANSFER|TATRAPAY|VIAMO|SPOROPAY|cardpay|VUBPAY|POSTAPAY|FIRSTDATAONDEMAND"
      );
    }
  };

  return (
    <>
      {insights && <Insights insights={insights} />}
      {payments?.data.length !== 0 && (
        <ZoozaTable disabled={loading}>
          <ZoozaTableHeader>
            <h3>
              {t("dashboard.list.title.payments")}{" "}
              {loading && <Loader className="spinner-button" />}
            </h3>

            <DateTimePicker
              required={false}
              date={moment(date).toDate()}
              help={""}
              setDate={setDate}
              format="iiii d.MM.yy"
            />
            <ZoozaSelect
              style={{
                width: isMobile ? "100px" : "200px",
                marginLeft: 10,
                marginRight: 10,

              }}
              param={"method"}
              onChange={onChangeMethod as any}
            >
              <option value="credit">
                {t("dashboard.payment.select.method.cash")}
              </option>
              <option value="cardpay">
                {t("dashboard.payment.select.method.credit")}
              </option>
              <option value="transfer">
                {t("dashboard.payment.select.method.transfer")}
              </option>
              <option value="all">
                {t("dashboard.payment.select.method.all")}
              </option>
            </ZoozaSelect>
            {!isMobile && <PaymentsColor setColor={setColor} colors={colors} />}
            <div className="results_found">
              {t("global.pagination.results", { results: payments?.total })}
            </div>
          </ZoozaTableHeader>
          <ZoozaTableBody>
            {payments?.data.map((payment) => (
              <ZoozaTableRow key={payment.id}>
                <ZoozaTableRowItem
                  style={{ backgroundColor: `var(--${payment.color})` }}
                >
                  <ZoozaTableCell span="2">
                    <span aria-label={t("dashboard.payments.list.amount")}>
                      {t("dashboard.payments.list.amount")}
                    </span>
                    {convertToCurrency(payment.amount, lang, currency)}
                  </ZoozaTableCell>
                  <ZoozaTableCell>
                    <span aria-label={t("dashboard.payments.list.origin")}>
                      {t("dashboard.payments.list.origin")}
                    </span>
                    {msg(payment.origin)}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="2">
                    <span
                      aria-label={t("dashboard.payments.list.variable_symbol")}
                    >
                      {t("dashboard.payments.list.variable_symbol")}
                    </span>
                    {payment.variable_symbol || ""}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="3">
                    <span
                      aria-label={t("dashboard.payments.list.transaction_type")}
                    >
                      {t("dashboard.payments.list.transaction_type")}
                    </span>
                    {payment.transaction_type}
                  </ZoozaTableCell>

                  <ZoozaTableCell span="4">
                    <span aria-label={t("dashboard.payments.list.value_date")}>
                      {t("dashboard.payments.list.value_date")}
                    </span>
                    {payment.value_date &&
                      moment(payment.value_date).locale(lang).format("LLLL")}
                  </ZoozaTableCell>
                </ZoozaTableRowItem>
              </ZoozaTableRow>
            ))}
          </ZoozaTableBody>
          <ZoozaTablePagination
            page={page}
            total={payments?.total}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </ZoozaTable>
      )}
    </>
  );
};

export const Insights = ({
  insights,
}: {
  insights: PaymentInsights | undefined;
}) => {
  const { t } = useTranslation();
  const { currency } = useContext<ZoozaContextTypes>(ZoozaContext);
  if (!insights) {
    return null;
  }
  const TableData = [
    {
      title: t("dashboard.insights.paired"),
      value: convertToCurrency(insights?.paired, lang, currency),
    },
    {
      title: t("dashboard.insights.unpaired"),
      value: convertToCurrency(insights?.unpaired, lang, currency),
    },
    {
      title: t("dashboard.insights.amount"),
      value: convertToCurrency(insights?.amount, lang, currency),
    },
    {
      title: t("dashboard.insights.cash"),
      value: convertToCurrency(insights?.cash, lang, currency),
    },
    {
      title: t("dashboard.insights.online_transfer"),
      value: convertToCurrency(insights?.online_transfer, lang, currency),
    },
    {
      title: t("dashboard.insights.online_card"),
      value: convertToCurrency(insights?.online_card, lang, currency),
    },
    {
      title: t("dashboard.insights.transfer"),
      value: convertToCurrency(insights?.transfer, lang, currency),
    },
  ];
  const TableData2 = [
    {
      title: t("dashboard.insights.courses"),
      value: convertToCurrency(insights?.courses, lang, currency),
    },
    {
      title: t("dashboard.insights.products"),
      value: convertToCurrency(insights?.products, lang, currency),
    },
  ];
  return (
    <ZoozaCard>
      <ZoozaCardHeader
        title={t("dashboard.table.title.insights")}
        icon="icon-analytics icon_large"
      ></ZoozaCardHeader>

      <ZoozaCardRow>
        {TableData.map((data) => (
          <ZoozaCardCell>
            <span aria-label={data.title}>{data.title}</span>
            <span>{data.value}</span>
          </ZoozaCardCell>
        ))}
      </ZoozaCardRow>
      <ZoozaCardRow>
        {TableData2.map((data) => (
          <ZoozaCardCell>
            <span aria-label={data.title}>{data.title}</span>
            <span>{data.value}</span>
          </ZoozaCardCell>
        ))}
      </ZoozaCardRow>

      <ZoozaCardActions>{""}</ZoozaCardActions>
    </ZoozaCard>
  );
};

const PaymentsColor = ({
  setColor,
  colors,
}: {
  setColor: (color: string[]) => void;
  colors: string[];
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const color = e.currentTarget.id;
    if (colors?.includes(color)) {
      setColor(colors.filter((c) => c !== color));
    } else {
      setColor([...colors, color]);
    }
  };
  const defcolors = [
    "veronese_green",
    "monticello",
    "zooza_orange",
    "tyrian_purple",
    "neutral",
  ];
  return (
    <div>
      <div className="swatch_list">
        {defcolors.map((color) => (
          <button
            id={color}
            onClick={onClick}
            style={{
              outline: colors?.includes(color) ? "2px solid black" : "",
            }}
            className={`color_swatch swatch_${color}`}
            key={color}
          ></button>
        ))}
      </div>
    </div>
  );
};
